import axios from '../config/axios.config';

const UserService = {
  google_login: async () => {
    return await axios.post(`/carter/google-auth`);
  },
  google_details: async (data) => {
    return await axios.get(
      `/carter/google-redirect?state=${data?.state}&code=${data?.code}&scope=${data?.scope}`
    );
  },

  facebook_login: async () => {
    return await axios.post(`/carter/fb-auth`);
  },
  facebook_details: async (data) => {
    return await axios.get(
      `/carter/fb-redirect?code=${data?.code}&state=${data?.state}`
    );
  },
};

export default UserService;

import React, { useEffect, useState } from 'react';

import { Button, Input, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import DynamicListTable from '../../components/Tables/Tables';
import { allAdsetsTableColumns } from '../../constants/columns';
import AdSetService from '../../services/adsets.service';

function Adsets() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [fullDataWithoutFilter, setFullDataWithoutFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const createAdsetHandler = () => {
    navigate('/adsets/create');
  };

  useEffect(() => {
    const fetchAllAdsets = async () => {
      setIsLoading(true);
      const response = await AdSetService.fetchAllAdsSets();
      let fetchedTableData = response?.data?.data || [];
      setTableData(fetchedTableData);
      setFullDataWithoutFilter(fetchedTableData);
      setIsLoading(false);
    };

    fetchAllAdsets();
  }, []);

  const onSearchAdsetsChangeHandler = (event) => {
    // If the value in the search box is empty, then
    // display the main data.
    if (event.target.value === '') setTableData(fullDataWithoutFilter);
    else
      setTableData(
        fullDataWithoutFilter.filter((adset) =>
          adset.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
  };

  return (
    <div className="flex flex-col">
      <div className="flex m-[30px] justify-between items-center align-middle">
        <Input
          size="large"
          placeholder={`Search Adsets`}
          className="placeholder:text-xs w-[32vw]"
          onPressEnter={onSearchAdsetsChangeHandler}
          suffix={<p className="text-xs font-light">Please enter to search</p>}
        />
        <Tooltip title="Not yet implemented">
          <Button
            onClick={createAdsetHandler}
            size="middle"
            className="bg-ca_primary text-white hover:bg-ca_primary_90"
            disabled={true}
          >
            Create Adset
          </Button>
        </Tooltip>
      </div>
      <div className="mx-[30px]">
        <DynamicListTable
          loading={isLoading}
          type="adsets"
          columns={allAdsetsTableColumns}
          dataSource={tableData}
          rowKey={tableData.id}
          onRowClick={null}
        />
      </div>
    </div>
  );
}

export default Adsets;

import { atom } from 'recoil';

export const userAppState = atom({
  key: 'user', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const userSelectedAccountState = atom({
  key: 'userSelectedAccount',
  default: {},
});

// The structure of the user will be used for storing
// the state and other data related to the user once the
// login process is complete.

// {
//     selectedUser :{
//         {
//             accountID:"1378888112738553",
//             name:"Shyam Sunder Suthar",
//             email:"shyam.suthar@shyftlabs.io"
//         }
//     },
//     userDetails: {"message":"AUTH_COMPLETE","email":"shyam.suthar@shyftlabs.io","name":"Shyam Sunder Suthar","google_account":{},"facebook_account":{"connected_accounts":["1378888112738553","1084890512577700"],"account_type":"FACEBOOK","page_access_token":"EAAfKXwEvzoYBO50mAY5ljDCH6kFYDdmZCUwEfYunuZABuKzPcd8G2xBE1H6IQL2n2bwpMpjN1CZAFuauTS7SVNh1DZCLaqROk9wiVH5qDOTE0nn6hT6IQmyoHkFk6EN6W4uxaFdRMhaGYkv69u9JZCNVPxKqIKmqLZAFfTub0bmS4BwfYZAjeKY5drjMtZAs0dBsrZAaMFpgZD"},"user_flag":false}
// }

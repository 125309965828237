import React from 'react';

import { Avatar, Badge, Divider, Dropdown } from 'antd';
import { Bell, User } from 'lucide-react';

import CarterLogo from '../../assets/svg/carter-one.svg';

const Header = (props) => {
  const items = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" href="">
          1st menu item
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      ),
    },
  ];
  return (
    <div
      className={`flex flex-row items-center justify-between py-3 border-b-[0.5px] max-h-[8vh] h-[8vh] pr-6`}
    >
      <div className="pl-6 text-2xl flex items-center justify-start w-[16vw] max-w-[16vw]">
        <div className="w-full pr-2 mr-4">
          <img src={CarterLogo} alt="Logo" className="pr-10 pl-0" />
        </div>
        <Divider type={'vertical'} className="h-[8vh] p-0 m-0" />
      </div>

      <div className="flex gap-4">
        <Badge count={5} status="warning">
          <Avatar
            size={32}
            icon={<Bell size={16} />}
            className="bg-ca_primary"
          />
        </Badge>
        {/* <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
        > */}
        <Avatar size={32} icon={<User size={16} />} className="bg-ca_primary" />
        {/* </Dropdown> */}
      </div>
      {/* <Input placeholder="Search" variant="filled" addonBefore={<Search strokeWidth={2} size={20} stroke="#6F5CD9"/>} className="w-[30vw] ml-10 bg-[#F2F2FD] rounded-lg" size="large"/> */}
    </div>
  );
};

export default Header;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign_ant-timeline-item-tail__i9dOL {

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.campaign_custom-input__rSYEj ::placeholder {

    font-size: 0.75rem;

    line-height: 1rem
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/campaign.module.scss"],"names":[],"mappings":"AACE;;IAAA,kBAAA;;IAAA;AAAA;;AAIA;;IAAA,kBAAA;;IAAA;AAAA","sourcesContent":[".ant-timeline-item-tail {\n  @apply bg-black;\n}\n\n.custom-input ::placeholder {\n  @apply text-xs;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ant-timeline-item-tail": `campaign_ant-timeline-item-tail__i9dOL`,
	"custom-input": `campaign_custom-input__rSYEj`
};
export default ___CSS_LOADER_EXPORT___;

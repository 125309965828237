import React, { useEffect, useState } from 'react';

import { Divider, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  DashboardOutlined,
  ExperimentOutlined,
  FundOutlined,
  LogoutOutlined,
  SettingFilled,
  TagsOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import {
  update,
  reset as NavigationStoreReset,
} from '../../redux/slices/navigation.slice';
import { reset as CampaignStoreReset } from '../../redux/slices/campaigns.slice';
import { reset as AuthStoreReset } from '../../redux/slices/auth.slice';
import AccountModal from '../Modal/AccountModal';
import styles from './sidebar-navigation.module.css';

const SideNavbar = () => {
  const dispatch = useDispatch();
  let navigation = useSelector((state) => state.navigation.value);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear session storage
    sessionStorage.clear();
    // Clear local storage
    localStorage.clear();
    // Clear redux store
    dispatch(NavigationStoreReset());
    dispatch(CampaignStoreReset());
    dispatch(AuthStoreReset());
    // Navigate to the signup page.
    navigate('/signup');
  };

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      dispatch(update(window.location.pathname.replace('/', '')));
      setInitialized(true);
    }
    dispatch(update(window.location.pathname.replace('/', '')));
  }, [dispatch, initialized]);

  const showModal = () => {
    dispatch(update('Accounts'));
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex flex-col h-[92vh] border-r-[0.5px]">
      <Menu
        inlineIndent={30}
        defaultSelectedKeys={[
          (navigation.length > 0 && navigation[navigation.length - 1]) || '',
        ]}
        selectedKeys={[
          (navigation.length > 0 && navigation[navigation.length - 1]) || '',
        ]}
        className="flex flex-col justify-center flex-grow overflow-y-auto max-w-[16vw] w-[16vw] gap-0 mt-0"
      >
        <Menu.Item
          key={'dashboard'}
          icon={<DashboardOutlined />}
          className={styles.dw_menu_item}
          onClick={() => dispatch(update('dashboard'))}
        >
          <Link to={'/dashboard'}>{'Dashboard'}</Link>
        </Menu.Item>
        <Menu.Item
          key={'campaigns'}
          icon={<FundOutlined />}
          className={styles.dw_menu_item}
          onClick={() => dispatch(update('campaigns'))}
        >
          <Link to={'/campaigns'}>{'Campaigns'}</Link>
        </Menu.Item>
        <Menu.Item
          key={'adsets'}
          icon={<ExperimentOutlined />}
          className={styles.dw_menu_item}
          onClick={() => dispatch(update('adsets'))}
        >
          <Link to={'/adsets'}>{'Adsets'}</Link>
        </Menu.Item>
        <Menu.Item
          key={'ads'}
          icon={<TagsOutlined />}
          className={styles.dw_menu_item}
          onClick={() => dispatch(update('ads'))}
        >
          <Link to={'/ads'}>{'Ads'}</Link>
        </Menu.Item>
        <Menu.Item
          key={'integration'}
          icon={<UsergroupAddOutlined />}
          className={styles.dw_menu_item}
          onClick={() => dispatch(update('integration'))}
        >
          <Link to={'/integration'}>{'Connect Account'}</Link>
        </Menu.Item>
        <Menu.Item
          key="Accounts"
          icon={<SettingFilled />}
          className={styles.dw_menu_item}
          onClick={showModal}
        >
          <span>Select Account</span>
        </Menu.Item>
        <AccountModal
          isVisible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        />
        <div className={styles.dw_menu_item}>
          <Divider className="m-0" />
          <Menu.Item
            key="logout"
            onClick={handleLogout}
            className={styles.dw_menu_item}
          >
            <LogoutOutlined /> Logout
          </Menu.Item>
        </div>
      </Menu>
    </div>
  );
};

export default SideNavbar;

import React, { useEffect, useState } from 'react';

import {
  Button,
  ConfigProvider,
  Modal,
  notification,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { AlertCircle, Smile, X } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { userSelectedAccountState } from 'src/recoil/user.atom';
import { setFacebookAuth, setGoogleAuth } from 'src/redux/slices/auth.slice';
import UserService from 'src/services/user-actions.service';

const { Option } = Select;

const AccountModal = ({ isVisible, onOk, onCancel, loading }) => {
  const [fbAccounts, setfbAccounts] = useState([]);
  const [googleAccounts, setgoogleAccounts] = useState([]);
  const [userData, setUserData] = useState({ name: '', email: '' });
  const [metaSelectedID, setMetaSelectedID] = useState('');
  const [googleSelectedID, setGoogleSelectedID] = useState('');
  const [onRefreshClicked, setOnRefreshClicked] = useState(false);
  const [loaderFb, setLoaderFb] = useState(true);
  const [loaderGoogle, setLoaderGoogle] = useState(true);

  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);

  // Recoil State for the Selected Account for Users
  const [userSelectedAccountAtom, setUserSelectedAccountAtom] = useRecoilState(
    userSelectedAccountState
  );

  useEffect(() => {
    if (userSelectedAccountAtom != null) {
      setMetaSelectedID(
        userSelectedAccountAtom?.selectedAccountAtom?.metaAccountId
      );
      setGoogleSelectedID(
        userSelectedAccountAtom?.selectedAccountAtom?.metaAccountId
      );
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      setUserData(userDetails);
      if (userDetails?.facebook_account?.connected_accounts) {
        setfbAccounts(userDetails.facebook_account.connected_accounts);
        setLoaderFb(false);
      }
      if (userDetails?.google_account?.connected_accounts) {
        setgoogleAccounts(userDetails.google_account.connected_accounts);
        setLoaderGoogle(false);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    handleStorageChange();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleMetaChange = (value) => {
    setMetaSelectedID(value);
  };

  const handleGoogleChange = (value) => {
    setGoogleSelectedID(value);
  };

  const handleOk = () => {
    if (metaSelectedID || googleSelectedID) {
      const selectedUser = {
        metaAccountId: metaSelectedID,
        googleAccountId: googleSelectedID,
        name: userData.name,
        email: userData.email,
      };

      setUserSelectedAccountAtom({ selectedAccountAtom: selectedUser });
      localStorage.setItem('selectedUser', JSON.stringify(selectedUser));
      window.dispatchEvent(new Event('accountSelected'));
      onOk();
    } else {
      console.error('Please select an account.');
    }
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
      icon:
        type === 'success' ? (
          <Smile style={{ color: '#108ee9' }} />
        ) : (
          <AlertCircle style={{ color: '#ff4d4f' }} />
        ),
    });
  };

  const onRefresh = async () => {
    setOnRefreshClicked(true);
    let response = null;
    try {
      if (
        authData &&
        authData.google_account &&
        authData.google_account.state &&
        authData.google_account.code &&
        authData.google_account.scope
      ) {
        response = await UserService.google_details({
          code: authData.google_account.code,
          state: authData.google_account.state,
          // scope: authData.google_account.scope,
        });
        setOnRefreshClicked(false);
      }
    } catch (err) {
      openNotificationWithIcon(
        'error',
        'Error fetching google account list',
        'An issue occurred while fetching the google account list data, try to login again from the Connect Account tab.'
      );
      setOnRefreshClicked(false);
    }
    try {
      if (
        authData &&
        authData.facebook_account &&
        authData.facebook_account.code &&
        authData.facebook_account.state
      ) {
        response = await UserService.facebook_details({
          code: authData.facebook_account.code,
          state: authData.facebook_account.state,
        });
        setOnRefreshClicked(false);
      }
    } catch (err) {
      openNotificationWithIcon(
        'error',
        'Error fetching facebook account list',
        'An issue occurred while fetching the facebook account list data, try to login again from the Connect Account tab.'
      );
      setOnRefreshClicked(false);
    }

    if (response && response.data && response.data.status_code === 201) {
      const userDetails = response.data.data;
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
      const facebookAuthPayload = {
        connected_accounts: userDetails.facebook_account.connected_accounts,
        page_type: userDetails.facebook_account.page_type,
        account_type: userDetails.facebook_account.account_type,
        page_access_token: userDetails.facebook_account.page_access_token,
      };
      const googleAuthPayload = {
        connected_accounts: userDetails.google_account.connected_accounts,
        account_type: userDetails.google_account.account_type,
      };
      dispatch(setFacebookAuth(facebookAuthPayload));
      dispatch(setGoogleAuth(googleAuthPayload));
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {
          Modal: {
            titleColor: 'black',
          },
        },
      }}
    >
      <Modal
        open={isVisible}
        onOk={handleOk}
        onCancel={onCancel}
        okButtonProps={{ type: 'primary' }}
        cancelButtonProps={{ type: 'default' }}
        closable={false}
        title={
          <div className="flex justify-between align-middle mr-4 fill-slate-300">
            <span>Account Settings</span>
            <Space>
              {/* <Tooltip openClassName="tooltip" title="Refresh Accounts">
                <Button
                  icon={<RefreshCcw color="grey" size={20} />}
                  type="text"
                  onClick={onRefresh}
                />
              </Tooltip> */}
              <Tooltip title="Close">
                <Button
                  icon={<X color="grey" />}
                  type="text"
                  onClick={onCancel}
                />
              </Tooltip>
            </Space>
          </div>
        }
      >
        <p className="mt-6 text-xs font-light mb-2 text-slate-600 opacity-50">
          Select a Facebook Account
        </p>
        <Select
          onChange={handleMetaChange}
          placeholder="Select an option"
          defaultValue={
            userSelectedAccountAtom?.selectedAccountAtom?.metaAccountId
          }
          disabled={onRefreshClicked}
          loading={loaderFb}
          defaultActiveFirstOption
        >
          {fbAccounts.map((account) => (
            <Option key={account.id} value={account.id}>
              {account.name}
            </Option>
          ))}
        </Select>
        <p className="mt-6 text-xs font-light mb-2 text-slate-600 opacity-50">
          Select a Google Account
        </p>
        <Select
          onChange={handleGoogleChange}
          placeholder="Select an option"
          // defaultValue={"6509895097"}
          defaultActiveFirstOption
          loading={loaderGoogle}
          disabled={onRefreshClicked}
        >
          {googleAccounts.map((account) => (
            <Option key={account.id} value={account.id}>
              {account.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </ConfigProvider>
  );
};

export default AccountModal;

import React from 'react';

import { ConfigProvider, Popconfirm, Tag } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';

const ConfiguredTag = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          paddingXXS: 1,
        },
      }}
    >
      <Tag color={props.text === 'auction' ? 'volcano' : 'geekblue'}>
        {props.text}
      </Tag>
    </ConfigProvider>
  );
};

const getStatusTag = (text) => {
  switch (text) {
    case 'FACEBOOK':
      return { color: 'blue', label: 'FACEBOOK' };
    case 'GOOGLE':
      return { color: 'cyan', label: 'GOOGLE' };
    case 'FALSE':
      return { color: 'volcano', label: 'False' };
    case 'TRUE':
      return { color: 'geekblue', label: 'True' };
    default:
      return { color: 'default', label: (text && text.toUpperCase()) || '---' };
  }
};

const ConfiguredStatusTag = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          paddingXXS: 1,
        },
      }}
    >
      <Tag color={props.text.toLowerCase() === 'active' ? 'volcano' : 'green'}>
        {props.text.toUpperCase() || 'NA'}
      </Tag>
    </ConfigProvider>
  );
};
export const allCampaignsTableColumns = [
  {
    title: 'Campaign Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Campaign ID',
    dataIndex: 'id',
    key: 'id',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: 150,
    ellipsis: true,
    filters: [
      {
        text: 'Facebook',
        value: 'FACEBOOK',
      },
      {
        text: 'Google',
        value: 'GOOGLE',
      },
    ],
    filterSearch: true,
    onFilter: (value, record) => {
      return record.platform === value;
    },
    render: (text) => {
      // if(text == null || text === undefined || text === 'undefined') return <p>---</p>;
      const { color, label } = getStatusTag(text);
      return (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
              paddingXXS: 1,
            },
          }}
        >
          <Tag color={color}>{label}</Tag>
        </ConfigProvider>
      );
    },
  },
  {
    title: 'Buying Type',
    dataIndex: 'buying_type',
    key: 'buying_type',
    width: 150,
    render: (text) => <ConfiguredTag text={text} />,
  },

  {
    title: 'Bid Strategy',
    dataIndex: 'bid_strategy',
    key: 'bid_strategy',
    width: 150,
    render: (text, record) => {
      const bidStrategy = (record?.bid_strategy || '--').replace(/_/g, ' ');
      return bidStrategy;
    },
    ellipsis: true,
  },
  {
    title: 'Daily Budget',
    dataIndex: 'daily_budget',
    key: 'daily_budget',
    width: 150,
    align: 'center',
    render: (text, record) => {
      const bidStrategy = record?.daily_budget || '---';
      return bidStrategy;
    },
    sorter: (a, b) => parseFloat(a.daily_budget) - parseFloat(b.daily_budget),
  },
  {
    title: 'Target Search',
    dataIndex: 'target_google_search',
    key: 'target_google_search',
    width: 150,
    render: (text, record) => {
      const targetNetwork =
        record.target_google_search === true ? 'TRUE' : 'FALSE';
      const { color, label } = getStatusTag(targetNetwork);
      return <Tag color={color}>{label}</Tag>;
    },
  },
  {
    title: 'Target Search Network',
    dataIndex: 'target_search_network',
    key: 'target_search_network',
    width: 150,
    render: (text, record) => {
      const targetNetwork =
        record.target_search_network === true ? 'TRUE' : 'FALSE';
      const { color, label } = getStatusTag(targetNetwork);
      return <Tag color={color}>{label}</Tag>;
    },
  },
  {
    title: 'Target Content',
    dataIndex: 'target_content_network',
    key: 'target_content_network',
    width: 150,
    render: (text, record) => {
      const targetNetwork =
        record.target_content_network === true ? 'TRUE' : 'FALSE';
      const { color, label } = getStatusTag(targetNetwork);
      return <Tag color={color}>{label}</Tag>;
    },
  },
  {
    title: 'Impressions',
    dataIndex: ['campaign_insights', 0, 'impressions'],
    key: 'impressions',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.impressions;
      return impressions;
    },
  },
  {
    title: 'Reach',
    dataIndex: ['campaign_insights', 0, 'reach'],
    key: 'reach',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.reach;
      return impressions;
    },
  },
  {
    title: 'Clicks',
    dataIndex: ['campaign_insights', 0, 'clicks'],
    key: 'clicks',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.clicks;
      return impressions;
    },
  },
  {
    title: 'Spend',
    dataIndex: ['campaign_insights', 0, 'spend'],
    key: 'spend',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.spend;
      return impressions;
    },
  },
  {
    title: 'Frequency',
    dataIndex: ['campaign_insights', 0, 'frequency'],
    key: 'frequency',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.frequency;
      return impressions;
    },
  },
  {
    title: 'CTR',
    dataIndex: ['campaign_insights', 0, 'ctr'],
    key: 'ctr',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.ctr;
      return impressions;
    },
  },
  {
    title: 'CPM',
    dataIndex: ['campaign_insights', 0, 'cpm'],
    key: 'cpm',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.cpm;
      return impressions;
    },
  },
  {
    title: 'CPC',
    dataIndex: ['campaign_insights', 0, 'cpc'],
    key: 'cpc',
    width: 150,
    render: (text, record) => {
      const impressions = record.campaign_insights?.[0]?.cpc;
      return impressions;
    },
  },

  {
    title: 'Start Time',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (text) => {
      const date = new Date(text);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    },
    sorter: (a, b) => {
      return new Date(a.start_time) - new Date(b.start_time);
    },
    width: 250,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '20px',
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#3066be',
            color: '#fff',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            alignItems: 'center',
            cursor: 'default',
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click event
          }}
        >
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                //handleDelete(record.key);
              }}
            />
          </Popconfirm>
        </span>
      </div>
    ),
    fixed: 'right',
  },
];

export const allAdsetsTableColumns = [
  {
    title: 'Adset Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Adset ID',
    dataIndex: 'id',
    key: 'id',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: 150,
    ellipsis: true,
    filters: [
      {
        text: 'Facebook',
        value: 'FACEBOOK',
      },
      {
        text: 'Google',
        value: 'GOOGLE',
      },
    ],
    filterSearch: true,
    onFilter: (value, record) => {
      return record.platform === value;
    },
    render: (text) => {
      if (text == null || text === undefined || text === 'undefined')
        return <p>---</p>;
      const { color, label } = getStatusTag(text);
      return (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
              paddingXXS: 1,
            },
          }}
        >
          <Tag color={color}>{label}</Tag>
        </ConfigProvider>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render: (text) => <ConfiguredStatusTag text={text} />,
  },
  {
    title: 'Billing Event',
    dataIndex: 'billing_event',
    key: 'billing_event',
    width: 150,
  },
  {
    title: 'Start Time',
    dataIndex: 'start_time',
    key: 'start_time',
    width: 250,
    render: (text) => {
      const date = new Date(text);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    },
  },
  {
    title: 'End Time',
    dataIndex: 'end_time',
    key: 'end_time',
    width: 250,
    render: (text) => {
      const date = new Date(text);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    },
  },
  {
    title: 'Impressions',
    dataIndex: ['adset_insights', 0, 'impressions'],
    key: 'impressions',
    width: 150,
    render: (text, record) => {
      const impressions = record.adset_insights?.[0]?.impressions;
      return impressions;
    },
  },
  {
    title: 'Reach',
    dataIndex: ['adset_insights', 0, 'reach'],
    key: 'reach',
    width: 150,
    render: (text, record) => {
      const reach = record.adset_insights?.[0]?.reach;
      return reach;
    },
  },
  {
    title: 'Clicks',
    dataIndex: ['adset_insights', 0, 'clicks'],
    key: 'clicks',
    width: 150,
    render: (text, record) => {
      const clicks = record.adset_insights?.[0]?.clicks;
      return clicks;
    },
  },
  {
    title: 'Spend',
    dataIndex: ['adset_insights', 0, 'spend'],
    key: 'spend',
    width: 150,
    render: (text, record) => {
      const spend = record.adset_insights?.[0]?.spend;
      return spend;
    },
  },
  {
    title: 'Frequency',
    dataIndex: ['adset_insights', 0, 'frequency'],
    key: 'frequency',
    width: 150,
    render: (text, record) => {
      const frequency = record.adset_insights?.[0]?.frequency;
      return frequency;
    },
  },
  {
    title: 'CTR',
    dataIndex: ['adset_insights', 0, 'ctr'],
    key: 'ctr',
    width: 150,
    render: (text, record) => {
      const ctr = record.adset_insights?.[0]?.ctr;
      return ctr;
    },
  },
  {
    title: 'CPM',
    dataIndex: ['adset_insights', 0, 'cpm'],
    key: 'cpm',
    width: 150,
    render: (text, record) => {
      const cpm = record.adset_insights?.[0]?.cpm;
      return cpm;
    },
  },
  {
    title: 'CPC',
    dataIndex: ['adset_insights', 0, 'cpc'],
    key: 'cpc',
    width: 150,
    render: (text, record) => {
      const cpc = record.adset_insights?.[0]?.cpc;
      return cpc;
    },
  },
  {
    title: 'Social Spend',
    dataIndex: ['adset_insights', 0, 'social_spend'],
    key: 'social_spend',
    width: 150,
    render: (text, record) => {
      const social_spend = record.adset_insights?.[0]?.social_spend;
      return social_spend;
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '20px',
        }}
      >
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#3066be',
            color: '#fff',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            alignItems: 'center',
            cursor: 'default',
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click event
          }}
        >
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                //handleDelete(record.key);
              }}
            />
          </Popconfirm>
        </span>
      </div>
    ),
    fixed: 'right',
  },
];

export const allAdsTableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    render: (text, record) => {
      return record.platform === 'GOOGLE' ? record.id : text;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render: (text) => <ConfiguredStatusTag text={text} />,
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: 150,
    ellipsis: true,
    filters: [
      {
        text: 'Facebook',
        value: 'FACEBOOK',
      },
      {
        text: 'Google',
        value: 'GOOGLE',
      },
    ],
    filterSearch: true,
    onFilter: (value, record) => record.platform === value,
    render: (text) => {
      const { color, label } = getStatusTag(text);
      return (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
              paddingXXS: 1,
            },
          }}
        >
          <Tag color={color}>{label}</Tag>
        </ConfigProvider>
      );
    },
  },
  {
    title: 'Impressions',
    dataIndex: ['ad_insights', 0, 'impressions'],
    key: 'impressions',
    width: 150,
    render: (text, record) => {
      const impressions = record.ad_insights?.[0]?.impressions;
      return impressions;
    },
  },
  {
    title: 'Reach',
    dataIndex: ['ad_insights', 0, 'reach'],
    key: 'reach',
    width: 150,
    render: (text, record) => {
      const reach = record.ad_insights?.[0]?.reach;
      return reach;
    },
  },
  {
    title: 'Clicks',
    dataIndex: ['ad_insights', 0, 'clicks'],
    key: 'clicks',
    width: 150,
    render: (text, record) => {
      const clicks = record.ad_insights?.[0]?.clicks;
      return clicks;
    },
  },
  {
    title: 'CTR',
    dataIndex: ['ad_insights', 0, 'ctr'],
    key: 'ctr',
    width: 150,
    render: (text, record) => {
      const ctr = record.ad_insights?.[0]?.ctr;
      return ctr;
    },
  },
  {
    title: 'CPM',
    dataIndex: ['ad_insights', 0, 'cpm'],
    key: 'cpm',
    width: 150,
    render: (text, record) => {
      const cpm = record.ad_insights?.[0]?.cpm;
      return cpm;
    },
  },
  {
    title: 'CPC',
    dataIndex: ['ad_insights', 0, 'cpc'],
    key: 'cpc',
    width: 150,
    render: (text, record) => {
      const cpc = record.ad_insights?.[0]?.cpc;
      return cpc;
    },
  },
  {
    title: 'Created time',
    dataIndex: 'created_time',
    key: 'created_time',
    width: 150,
    render: (text) => {
      try {
        const date = new Date(text);
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      } catch (error) {
        console.log('Error parsing date:', error);
        return 'Invalid Date';
      }
    },
    sorter: (a, b) => new Date(a.created_time) - new Date(b.created_time),
  },
];

export const adsetCampaignDetails = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Billing Event',
    dataIndex: 'billing_event',
    key: 'billing_event',
  },
  {
    title: 'Bid Amount',
    dataIndex: 'bid_amount',
    key: 'bid_amount',
    render: (record) => {
      const bidAmount = (record?.bid_amount || '--').replace(/_/g, ' ');
      return bidAmount;
    },
  },
  {
    title: 'Start time',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (text) => {
      try {
        const date = new Date(text);
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      } catch (error) {
        console.log('Error parsing date:', error);
        return 'Invalid Date';
      }
    },
  },
];

export const adsCampiagnDetails = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Created at',
    dataIndex: 'created_time',
    key: 'created_time',
    render: (text) => {
      try {
        const date = new Date(text);
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      } catch (error) {
        console.log('Error parsing date:', error);
        return 'Invalid Date';
      }
    },
  },
];

function moment(date) {
  throw new Error('Function not implemented.');
}

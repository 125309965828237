// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaings_placeholder__0TFxk ::placeholder {
  line-height: 20px;
  font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Campaigns/Campaigns/campaings.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF","sourcesContent":[".placeholder ::placeholder {\n  line-height: 20px;\n  font-size: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `campaings_placeholder__0TFxk`
};
export default ___CSS_LOADER_EXPORT___;

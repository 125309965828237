import '../../../src/styles/Campaign.css';
import './campaign.module.scss';

import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  Timeline,
} from 'antd';
import { ChevronDown } from 'lucide-react';

import facebook from '../../assets/facebook.png';
import google from '../../assets/google.png';
import { CAMPAIGN_LABELS, disabledDate } from '../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setCampaignName as rdxName } from 'src/redux/slices/campaigns.slice';

const CampignName = ({ title, onChange }) => {
  const dispatch = useDispatch();
  let rdxCampaign = useSelector((state) => state.campaign);

  return (
    <div className="ml-6 mb-12">
      <div className="text-lg mb-4">{title}</div>
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {CAMPAIGN_LABELS.CAMPAIGN_NAME}
          </p>
          <Input
            onChange={(e) => {
              onChange(e.target.value);
              dispatch(
                rdxName({
                  val: e.target.value,
                })
              );
            }}
            defaultValue={rdxCampaign.campaignName}
            size="large"
            className="w-[32vw]"
            required
          />
        </div>
      </div>
    </div>
  );
};
const AdsPlatform = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      <div className="text-lg mb-4">{title}</div>
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            Select Platform *
          </p>
          <Radio.Group
            onChange={(e) => {
              onChange(e.target.value, 'platform');
            }}
          >
            <div className="flex gap-4">
              <div className="w-36 max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden border">
                <div className="p-6">
                  <div className="flex justify-center mb-4">
                    <img src={google} alt="Logo" className="w-16 h-16" />
                  </div>
                  <div className="flex justify-center ">
                    <p className="text-sm font-bold text-indigo-600 opacity-80">
                      Google
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <Radio value={'gads'}></Radio>
                  </div>
                </div>
              </div>

              <div className=" w-36 max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden border">
                <div className="p-6">
                  <div className="flex justify-center mb-4">
                    <img src={facebook} alt="Logo" className="w-16 h-16" />
                  </div>
                  <div className="flex justify-center ">
                    <p className="text-sm font-bold text-indigo-600 opacity-80">
                      Facebook
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <Radio value={'fbads'}></Radio>
                  </div>
                </div>
              </div>
            </div>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};
const CampaignDetails = ({ title, onChange, platform }) => {
  const platformValue = platform && platform.platform;
  const campaignObjectiveOptions =
    platformValue === 'gads'
      ? [{ label: 'Search', value: 'SEARCH', key: 'SEARCH' }]
      : [{ label: 'Awareness', value: 'OUTCOME_AWARENESS', key: 'AWARENESS' }];
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        {platformValue === 'fbads' && (
          <div>
            <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
              {CAMPAIGN_LABELS.CAMPAIGN_TYPE}
            </p>
            <Select
              size={'large'}
              onChange={(value) => onChange(value, 'campaignType')}
              options={[
                { value: 'AUCTION', label: 'Auction', key: 'AUCTION' },
                {
                  value: 'RESERVATION',
                  label: 'Reservation',
                  key: 'RESERVATION',
                  disabled: true,
                },
              ]}
              className="w-[32vw]"
              suffixIcon={
                <ChevronDown
                  size={16}
                  strokeWidth={1}
                  className="text-ca_primary bg-ca_secondary rounded-full"
                />
              }
            />
          </div>
        )}
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {CAMPAIGN_LABELS.CAMPAIGN_OBJ}
          </p>
          <Select
            size={'large'}
            onChange={(value) => onChange(value, 'campaignObjective')}
            options={campaignObjectiveOptions}
            className="w-[32vw]"
            suffixIcon={
              <ChevronDown
                size={16}
                strokeWidth={1}
                className="text-ca_primary bg-ca_secondary rounded-full"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

const Budgeting = ({ title, onChange, platform }) => {
  const platformValue = platform && platform.platform;
  const { Option } = Select;

  const selectBefore = (
    <Select defaultValue="rupees">
      <Option value="rupees">₹</Option>
      <Option value="dollars">$</Option>
    </Select>
  );
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {CAMPAIGN_LABELS.DAILY_BUDGET}
          </p>
          <InputNumber
            onChange={(value) => onChange(value, 'dailyBudget')}
            className="w-[32vw]"
            size="large"
            addonBefore={selectBefore}
          />
          {/* <Input addonBefore={selectBefore} addonAfter={selectAfter} defaultValue="mysite" /> */}
        </div>
        {platformValue === 'gads' && (
          <div>
            <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
              {CAMPAIGN_LABELS.BUDGET_DELIVERY_TYPE}
            </p>
            <Select
              size={'large'}
              onChange={(value) => onChange(value, 'budgetDelivery')}
              options={[
                { value: 'STANDARD', label: 'STANDARD', key: 'TRAFFIC' },
              ]}
              className="w-[32vw]"
              suffixIcon={
                <ChevronDown
                  size={16}
                  strokeWidth={1}
                  className="text-ca_primary bg-ca_secondary rounded-full"
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const BiddingStrategy = ({ title, onChange, platform }) => {
  const platformValue = platform && platform.platform;
  const biddinStartegyOptions =
    platformValue === 'gads'
      ? [
          {
            value: 'LOWEST_COST_WITH_MIN_ROAS',
            label: 'Lowest cost withh minimum ROAS',
          },
          {
            value: 'MAXIMIZE_CONVERSIONS',
            label: 'Maximize Conversions',
          },
          {
            value: 'MAXIMIZE_CONVERSION_VALUE',
            label: 'Maximize Conversion Value',
          },
          {
            value: 'TARGET_SPEND',
            label: 'Target Spend',
          },
          {
            value: 'TARGET_IMPRESSION_SHARE',
            label: 'Target Impression Share',
          },
        ]
      : [
          {
            value: 'LOWEST_COST_WITHOUT_CAP',
            label: 'Lowest cost without cap',
          },
          {
            value: 'LOWEST_COST_WITH_BID_CAP',
            label: 'Lowest cost with bid cap',
          },
          { value: 'COST_CAP', label: 'Cost cap' },
        ];

  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {CAMPAIGN_LABELS.BIDDING_STRATEGY}
          </p>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50"></p>
          <Select
            className="w-[32vw]"
            size="large"
            onChange={(value) => onChange(value)}
            options={biddinStartegyOptions}
            suffixIcon={
              <ChevronDown
                size={16}
                strokeWidth={1}
                className="text-ca_primary bg-ca_secondary rounded-full"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

const CampignDuration = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {CAMPAIGN_LABELS.START_DATE}
          </p>
          <DatePicker
            size="large"
            className="w-[32vw]"
            required
            disabledDate={disabledDate}
            onChange={(date) => onChange(date, 'startDate')}
          />
        </div>
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {CAMPAIGN_LABELS.END_DATE}
          </p>
          <DatePicker
            size="large"
            className="w-[32vw]"
            required
            disabledDate={disabledDate}
            onChange={(date) => onChange(date, 'endDate')}
          />
        </div>
      </div>
    </div>
  );
};

const TargetSettings = ({ title, onChange }) => {
  const [targetSettings, setTargetSettings] = useState([]);

  const handleChange = (values) => {
    const updatedTargetSettings = Array.isArray(values) ? values : [values];
    setTargetSettings(updatedTargetSettings);
    onChange(updatedTargetSettings, 'targetSettings');
  };
  return (
    <div className="ml-6 mb-12">
      <div className="text-lg mb-4">{title}</div>
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            Select Platform *
          </p>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Checkbox.Group onChange={handleChange}>
              <Checkbox value="targetGoogleSearch">
                Target Google search
              </Checkbox>
              <Checkbox value="targetSearchNetwork">
                Target search network
              </Checkbox>
              <Checkbox value="targetPartnerSearchNetwork">
                Target partner search network
              </Checkbox>
              <Checkbox value="targetContentNetwork">
                Target content network
              </Checkbox>
            </Checkbox.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dot = (props) => {
  const type =
    props.type === 'dark'
      ? 'bg-ca_primary text-white'
      : 'bg-ca_secondary text-ca_primary';
  return (
    <div
      className={`rounded-full w-[40px] h-[40px] flex items-center justify-center ${type}`}
    >
      {props.value}
    </div>
  );
};

const CreateCampaignForm = ({ onFinish }) => {
  const [campaignData, setCampaignData] = useState({
    campaignName: '',
    campaignSetting: {
      campaignType: '',
      campaignObjective: '',
    },
    budgetData: {
      dailyBudget: undefined,
      budgetDelivery: undefined,
    },
    selectedStrategy: null,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    platform: '',
    targetSettings: [],
  });

  const handleChange = (value, field, nestedField = null) => {
    setCampaignData((prevState) => {
      if (nestedField) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [nestedField]: value,
          },
        };
      }
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      const campignFormData = {
        campaign_data: {
          name: campaignData.campaignName,
          buying_type: campaignData.campaignSetting.campaignType,
          objective: campaignData.campaignSetting.campaignObjective,
          daily_budget: campaignData.budgetData.dailyBudget,
          budget_delivery_type: campaignData.budgetData.budgetDelivery,
          bid_strategy: campaignData.selectedStrategy,
          start_time: campaignData.dateRange.startDate,
          end_time: campaignData.dateRange.endDate,
          target_google_search:
            // @ts-ignore
            campaignData.targetSettings?.targetSettings?.includes(
              'targetGoogleSearch'
            ),
          target_search_network:
            // @ts-ignore
            campaignData.targetSettings?.targetSettings?.includes(
              'targetSearchNetwork'
            ),
          target_partner_search_network:
            // @ts-ignore
            campaignData.targetSettings?.targetSettings?.includes(
              'targetPartnerSearchNetwork'
            ),
          target_content_network:
            // @ts-ignore
            campaignData.targetSettings?.targetSettings?.includes(
              'targetContentNetwork'
            ),
        },
        platform: campaignData.platform,
      };
      onFinish(campignFormData);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };
  const platformValue =
    campaignData.platform && campaignData.platform['platform'];

  const timelineItems = [
    {
      dot: <Dot value="1.1" type="dark" />,
      children: (
        <CampignName
          title="Campaign Name"
          onChange={(value) => handleChange(value, 'campaignName')}
        />
      ),
    },
    {
      dot: <Dot value="1.2" type="light" />,
      children: (
        <AdsPlatform
          title="Ads Platform"
          onChange={(value, field) => handleChange(value, 'platform', field)}
        />
      ),
    },
    {
      dot: <Dot value="1.3" type="light" />,
      children: (
        <CampaignDetails
          title="Campaign Details"
          onChange={(value, field) =>
            handleChange(value, 'campaignSetting', field)
          }
          platform={campaignData.platform}
        />
      ),
    },
    {
      dot: <Dot value="1.4" type="light" />,
      children: (
        <Budgeting
          title="Budgeting"
          onChange={(value, field) => handleChange(value, 'budgetData', field)}
          platform={campaignData.platform}
        />
      ),
    },
    {
      dot: <Dot value="1.5" type="light" />,
      children: (
        <BiddingStrategy
          title="Bidding Strategy"
          onChange={(value) => handleChange(value, 'selectedStrategy')}
          platform={campaignData.platform}
        />
      ),
    },
    {
      dot: <Dot value="1.6" type="light" />,
      children: (
        <CampignDuration
          title="Campaign Duration"
          onChange={(value, field) => handleChange(value, 'dateRange', field)}
        />
      ),
    },
  ];

  if (platformValue === 'gads') {
    timelineItems.push({
      dot: <Dot value="1.7" type="light" />,
      children: (
        <TargetSettings
          title="Target Settings"
          onChange={(value, field) =>
            handleChange(value, 'targetSettings', field)
          }
        />
      ),
    });
  }
  return (
    <div className="">
      <Timeline className="m-5" items={timelineItems} />
      <div className="form-buttons-container">
        <Button type="default">
          {/* onClick={() => setActiveForm("Campaigns")} */}
          Back
        </Button>
        <Button className="bg-ca_primary" type="primary" onClick={handleSubmit}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CreateCampaignForm;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  name: '',
  google_account: {
    connected_accounts: [],
    account_type: '',
    state: '',
    code: '',
    scope: '',
  },
  facebook_account: {
    connected_accounts: [],
    account_type: '',
    page_token: '',
    code: '',
    state: '',
  },
  user_flag: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setGoogleAuth(state, action) {
      const { google_account } = state;
      const {
        connected_accounts = google_account.connected_accounts,
        user_flag = state.user_flag,
        scope = google_account.scope,
        googleState = google_account.state,
        accountType = google_account.account_type,
        code = google_account.code,
      } = action.payload;

      state.google_account.connected_accounts = connected_accounts;
      state.user_flag = user_flag;
      state.google_account.scope = scope;
      state.google_account.account_type = accountType;
      state.google_account.state = googleState;
      state.google_account.code = code;
    },

    setGoogleStateCodeScope(state, action) {
      state.google_account.state = action.payload.state;
      state.google_account.code = action.payload.code;
      state.google_account.scope = action.payload.scope;
    },

    setUserEmailName(state, action) {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.user_flag = action.payload.user_flag;
    },

    setFacebookAuth(state, action) {
      const { facebook_account } = state;
      const {
        connected_accounts = facebook_account.connected_accounts,
        user_flag = state.user_flag,
        facebookState = facebook_account.state,
        code = facebook_account.code,
      } = action.payload;
      state.facebook_account.connected_accounts = connected_accounts;
      state.user_flag = user_flag;
      state.facebook_account.state = facebookState;
      state.facebook_account.code = code;
    },

    setFacebookStateCodeScope(state, action) {
      state.facebook_account.state = action.payload.state;
      state.facebook_account.code = action.payload.code;
    },

    //Reset state
    reset(state) {
      state = { ...initialState };
      return state;
    },
  },
});

export const {
  setGoogleAuth,
  setFacebookAuth,
  setFacebookStateCodeScope,
  setGoogleStateCodeScope,
  setUserEmailName,
  reset,
} = authSlice.actions;
export default authSlice.reducer;

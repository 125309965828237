import React, { useEffect, useState } from 'react';
import { Card, Tag } from 'antd';
import facebook from '../../assets/facebook.png';
import google from '../../assets/google.png';
import connect from '../../assets/Frame.png'; // Assuming you have a google.png in your assets
import { ACCOUNT_CONSTANTS } from '../../common/constants';
import GoogleSignIn from 'src/assets/svg/GoogleSignIn.svg';
import FacebookSignIn from 'src/assets/svg/FacebookSignin.svg';
import UserService from 'src/services/user-actions.service';
import showToast from 'src/utilities/toast';
import { Button, Divider } from 'antd';
import Icon, {
  EditOutlined,
  RedoOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

const Accounts = () => {
  const [userDetails, setUserDetails] = useState({ name: '', email: '' });
  const [facebookConnected, setFacebookConnected] = useState(false);
  const [googleConnected, setGoogleConnected] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const localStorageData = localStorage.getItem('userDetails');
      if (localStorageData) {
        const userData = JSON.parse(localStorageData);
        console.log(userData);
        setUserDetails(userData);
        setFacebookConnected(
          userData?.facebook_account?.connected_accounts?.length > 0
        );
        setGoogleConnected(
          userData?.google_account?.connected_accounts?.length > 0
        );
      }
    };

    checkAuthentication();
  }, []);

  const HandleGoogleLogin = async () => {
    try {
      const response = await UserService.google_login();
      if (response.status === 200) {
        const authUrl = response.data.data;
        window.location.href = authUrl;
      } else {
        showToast('error', 'Google Login Error', response.data.detail);
      }
    } catch (error) {
      showToast('error', 'Error - Google Login', error.message);
    }
  };

  const handleFacebookLogin = async () => {
    try {
      const response = await UserService.facebook_login();
      if (response.status === 200) {
        const authUrl = response.data.data;
        window.location.href = authUrl;
      } else {
        showToast('error', 'Google Login Error', response.data.detail);
      }
    } catch (error) {
      console.error(error);
      showToast('error', 'Error - Google Login', error.message);
    }
  };

  return (
    <div className="px-10 overflow-scroll h-[100vh] bg-slate-100">
      <div className="bg-white p-5 mt-5 rounded-lg mb-10">
        <h1 className="text-2xl semi-bold">Integrated Channel</h1>
        <p className=" font-roboto text-base font-extralight mt-2">
          Select an advertising platform to run this campaign*{' '}
          <InfoCircleOutlined />
        </p>
        <div className="flex gap-5 m-5">
          {/* Facebook Card */}
          {facebookConnected ? (
            <Card className="w-120 flex flex-col rounded-lg border border-gray-300">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-6 items-center">
                  <Button className="h-8 w-22 border border-gray-300 rounded-md font-poppins text-ca_primary_90 font-semibold flex justify-center items-center">
                    {' '}
                    {ACCOUNT_CONSTANTS.DISCONNECTED_TEXT}
                  </Button>
                  <img className="w-13 h-12" src={facebook} alt="FB" />
                  <Tag
                    className={`rounded-full border-none text-xs px-2 py-1 bg-green-300`}
                  >
                    <span className={`text-green-700`}>
                      {ACCOUNT_CONSTANTS.CONNECTED_TEXT}
                    </span>
                  </Tag>
                </div>

                <div className="h-14 bg-gray-200 p-2 flex justify-between items-center rounded-lg px-4">
                  <div className="mr-2">
                    <p className="text-gray-800 font-semibold text-xs">
                      {ACCOUNT_CONSTANTS.USER_NAME_LABEL}
                    </p>
                    <p className="text-gray-600 text-xm">{userDetails.name}</p>
                  </div>
                  <div>
                    <p className="text-gray-800 font-semibold text-xs">
                      {ACCOUNT_CONSTANTS.USER_EMAIL_LABEL}
                    </p>
                    <p className="text-gray-600 text-xm">{userDetails.email}</p>
                  </div>
                  <Button
                    icon={<EditOutlined />}
                    className="h-8 w-16 border border-gray-300  rounded-md font-poppins text-ca_primary_90 font-semibold flex justify-center items-center"
                  >
                    {ACCOUNT_CONSTANTS.EDIT_BUTTON_TEXT}
                  </Button>
                </div>
              </div>
              <Divider className="border border-gray-300" />
              <div className="flex justify-around items-center">
                <RedoOutlined />
                <b>
                  <u>Reset Connection</u>
                </b>
                <InfoCircleOutlined />
              </div>
            </Card>
          ) : (
            <Card className="w-64 flex flex-col rounded-lg border border-gray-300">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-6 items-center">
                  <p>
                    Status:{' '}
                    <Tag
                      className={`rounded-full border-none text-xs px-2 py-1 bg-gray-200`}
                    >
                      <span className={`text-gray-700`}>
                        {ACCOUNT_CONSTANTS.NOT_CONNECTED_TEXT}
                      </span>
                    </Tag>
                  </p>

                  <img className="w-20 h-20" src={facebook} alt="FB" />
                  <h2 className="text-base">Facebook</h2>
                  <p className="text-xs">Account Name</p>
                  <Button className="h-8 w-22 bg-ca_primary flex items-center justify-center">
                    <img src={connect} alt="FrameIcon" className="h-4 w-4" />
                    <span className="ml-2 text-white">Connect</span>
                  </Button>
                </div>
              </div>
            </Card>
          )}

          {/* Google Card */}
          {googleConnected ? (
            <Card className="w-120 flex flex-col rounded-lg border border-gray-300">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-6 items-center">
                  <Button className="h-8 w-22 border border-gray-300 rounded-md font-poppins text-ca_primary_90 font-semibold flex justify-center items-center">
                    Disconnect
                  </Button>
                  <img className="w-13 h-12" src={google} alt="Google" />
                  <Tag
                    className={`rounded-full text-xs px-2 py-1 border-none bg-green-300`}
                  >
                    <span
                      className={
                        googleConnected ? 'text-green-700' : 'text-red-700'
                      }
                    >
                      {ACCOUNT_CONSTANTS.CONNECTED_TEXT}
                    </span>
                  </Tag>
                </div>
                <div className="h-14 bg-gray-200 flex justify-around items-center rounded-lg p-2">
                  <div>
                    <p className="text-gray-800 font-semibold text-xs">
                      {ACCOUNT_CONSTANTS.USER_NAME_LABEL}
                    </p>
                    <p className="text-gray-600 text-xm">{userDetails.name}</p>
                  </div>
                  <div>
                    <p className="text-gray-800 font-semibold text-xs">
                      {ACCOUNT_CONSTANTS.USER_EMAIL_LABEL}
                    </p>
                    <p className="text-gray-600 text-xm">{userDetails.email}</p>
                  </div>
                  <Button
                    icon={<EditOutlined />}
                    className="h-8 w-16 border border-gray-300  rounded-md font-poppins text-ca_primary_90 font-semibold flex justify-center items-center"
                  >
                    {ACCOUNT_CONSTANTS.EDIT_BUTTON_TEXT}
                  </Button>
                </div>
              </div>
              <Divider className="border border-gray-300" />
              <div className="flex justify-around items-center">
                <RedoOutlined />
                <b>
                  <u>Reset Connection</u>
                </b>
                <InfoCircleOutlined />
              </div>
            </Card>
          ) : (
            <Card className="w-64 flex flex-col rounded-lg border border-gray-300">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-6 items-center">
                  <p>
                    Status:{' '}
                    <Tag
                      className={`rounded-full border-none text-xs px-2 py-1 bg-gray-200`}
                    >
                      <span className={`text-gray-700`}>
                        {ACCOUNT_CONSTANTS.NOT_CONNECTED_TEXT}
                      </span>
                    </Tag>
                  </p>

                  <img className="w-20 h-20" src={google} alt="FB" />
                  <h2 className="text-base">Google</h2>
                  <p className="text-xs">Account Name</p>
                  <Button className="h-8 w-22 bg-ca_primary flex items-center justify-center">
                    <img src={connect} alt="FrameIcon" className="h-4 w-4" />
                    <span className="ml-2 text-white">Connect</span>
                  </Button>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>

      {/* <div className="bg-white p-5 mt-5 rounded-lg mb-10">
        <h1 className='text-2xl semi-bold'>Non Integrated Channel</h1>
        <p className=' font-roboto text-base font-extralight mt-2'>Select an advertising platform to run this campaign* <InfoCircleOutlined /></p>
        <div className="flex gap-5 m-5">
        
          <Card className="w-64 flex flex-col rounded-lg border border-gray-300">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-6 items-center">
                <p>Status: <Tag
                  className={`rounded-full border-none text-xs px-2 py-1 bg-gray-200`}
                >
                  <span className={`text-gray-700`}>
                    {ACCOUNT_CONSTANTS.NOT_CONNECTED_TEXT}
                  </span>
                </Tag></p>
               
                <img className="w-20 h-20" src={facebook} alt="FB" />
                <h2 className='text-base'>Facebook</h2>
                <p className='text-xs'>Account Name</p>
                <Button className='h-8 w-22 bg-ca_primary flex items-center justify-center'>
                  <img src={connect} alt="FrameIcon" className="h-4 w-4" />
                  <span className="ml-2 text-white">Connect</span>
                </Button>
                 </div>
            </div>
          </Card>

          <Card className="w-64 flex flex-col rounded-lg border border-gray-300">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-6 items-center">
                <p>Status: <Tag
                  className={`rounded-full border-none text-xs px-2 py-1 bg-gray-200`}
                >
                  <span className={`text-gray-700`}>
                    {ACCOUNT_CONSTANTS.NOT_CONNECTED_TEXT}
                  </span>
                </Tag></p>
               
                <img className="w-20 h-20" src={google} alt="FB" />
                <h2 className='text-base'>Google</h2>
                <p className='text-xs'>Account Name</p>
                <Button className='h-8 w-22 bg-ca_primary flex items-center justify-center'>
                  <img src={connect} alt="FrameIcon" className="h-4 w-4" />
                  <span className="ml-2 text-white">Connect</span>
                </Button>
                 </div>
            </div>
          </Card>
        </div>
      </div> */}
    </div>

    //  {googleConnected ? (

    // : (
    //   <div
    //     className="flex flex-row items-center w-full justify-center align-middle"
    //     onClick={HandleGoogleLogin}
    //   >
    //     <img src={GoogleSignIn} alt="" width={250} />
    //   </div>
    // )}
  );
};

export default Accounts;

import './Tables.css';

import React from 'react';

import { ConfigProvider, Spin, Table } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAppState } from 'src/recoil/user.atom';

const DynamicListTable = ({
  type,
  dataSource,
  columns,
  rowKey = 'id',
  onRowClick,
  loading,
}) => {
  const userAtom = useRecoilValue(userAppState);
  return (
    <>
      <div>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellFontSizeMD: 12,
                cellFontSizeSM: 12,
              },
            },
          }}
        >
          <p className="text-xs mb-2">
            Total Count :{' '}
            <span>
              {dataSource.length ? (
                <span>{dataSource.length}</span>
              ) : (
                <span className="text-red-600">NA</span>
              )}{' '}
            </span>{' '}
          </p>
          <Table
            loading={loading}
            columns={columns}
            scroll={{
              x: 1300,
            }}
            size="middle"
            className="text-sm cursor-pointer mt-0"
            dataSource={dataSource}
            rowKey={rowKey}
            onRow={
              onRowClick
                ? (row) => ({ onClick: () => onRowClick(row) })
                : undefined
            }
            bordered
            pagination={{
              position: ['none', 'bottomCenter'],
            }}
          />
        </ConfigProvider>
      </div>
    </>
  );
};

export default DynamicListTable;

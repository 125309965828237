import React from 'react';

import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component }) => {
  const isAuthenticated = () => localStorage.getItem('userDetails') !== null;
  return (
    <>
      {/* {isAuthenticated() ? <Component /> : <Navigate to={'/signup'} replace />} */}
      <Component />
    </>
  );
};

export default ProtectedRoute;

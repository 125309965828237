import '../../../src/styles/Campaign.css';

import React, { useState } from 'react';

import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Timeline,
} from 'antd';
import { ChevronDown } from 'lucide-react';

import { ADSET_LABELS } from '../../common/constants';

const AdsSetsName = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADSET_LABELS.ADSET_NAME}
          </p>
          <Input
            onChange={(e) => onChange(e.target.value, 'adSetName')}
            size="large"
            className="w-[32vw]"
            required
          />
        </div>
      </div>
    </div>
  );
};

const AdsSetGoal = ({ title, onChange, platform }) => {
  const platformValue = platform && platform.platform;
  const payingEventOptions =
    platformValue === 'gads'
      ? [
          {
            value: 'SEARCH_STANDARD',
            label: 'Standard Search',
            key: 'SEARCH_STANDARD',
          },

          {
            value: 'DISPLAY_STANDARD',
            label: 'Standard Display',
            key: 'DISPLAY_STANDARD',
            disabled: true,
          },
        ]
      : [
          {
            value: 'IMPRESSIONS',
            label: 'On Ad display',
            key: 'RESERVATION',
          },
          {
            value: 'CLICKS',
            label: 'On Ad click',
            key: 'CLICKS',
            disabled: true,
          },
        ];
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADSET_LABELS.PAYING_EVENT}
          </p>
          <Select
            size={'large'}
            onChange={(value) => onChange(value, 'adSetBuyingType')}
            options={payingEventOptions}
            className="w-[32vw]"
            suffixIcon={
              <ChevronDown
                size={16}
                strokeWidth={1}
                className="text-ca_primary bg-ca_secondary rounded-full"
              />
            }
          />
        </div>
        {platformValue === 'fbads' && (
          <div>
            <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
              {ADSET_LABELS.OPTIMIZATION_GOAL}
            </p>
            <Select
              size={'large'}
              onChange={(value) => onChange(value, 'adSetOptimizationGoal')}
              options={[
                { value: 'Maximise number of impressions', key: 'IMPRESSIONS' },
                {
                  value: 'Maximise reach of ads',
                  key: 'CLICKS',
                  disabled: true,
                },
                {
                  value: 'Maximise ad recall lift',
                  key: 'AD_RECALL_LIFT',
                  disabled: true,
                },
              ]}
              className="w-[32vw]"
              suffixIcon={
                <ChevronDown
                  size={16}
                  strokeWidth={1}
                  className="text-ca_primary bg-ca_secondary rounded-full"
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const AgeRange = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADSET_LABELS.MIN_AGE}
          </p>
          <InputNumber
            onChange={(value) => onChange(value, 'minAge')}
            className="w-[32vw]"
            size="large"
            min={18}
            max={100}
          />
        </div>
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADSET_LABELS.MAX_AGE}
          </p>
          <InputNumber
            onChange={(value) => onChange(value, 'maxAge')}
            className="w-[32vw]"
            size="large"
            min={18}
            max={100}
          />
        </div>
      </div>
    </div>
  );
};

const Gender = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            Gender
          </p>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50"></p>
          <Select
            className="w-[32vw]"
            size="large"
            onChange={(value) => onChange(value, 'genders')}
            mode="multiple"
            options={[
              {
                value: '1',
                label: 'MALE',
              },
              {
                value: '2',
                label: 'FEMALE',
              },
            ]}
            suffixIcon={
              <ChevronDown
                size={16}
                strokeWidth={1}
                className="text-ca_primary bg-ca_secondary rounded-full"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
const Location = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            Location
          </p>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50"></p>
          <Select
            className="w-[32vw]"
            size="large"
            onChange={(value) => onChange(value, 'location')}
            mode="multiple"
            options={[
              {
                value: 'IN',
                label: 'INDIA',
              },
              {
                value: 'CA',
                label: 'CANADA',
              },
              {
                value: 'US',
                label: 'UNITED STATES OF AMERICA',
              },
            ]}
            suffixIcon={
              <ChevronDown
                size={16}
                strokeWidth={1}
                className="text-ca_primary bg-ca_secondary rounded-full"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

const BidAmount = ({ title, onChange, bidAmount }) => {
  const disable = bidAmount === 'LOWEST_COST_WITHOUT_CAP';
  const { Option } = Select;

  const selectBefore = (
    <Select defaultValue="rupees">
      <Option value="rupees">₹</Option>
      <Option value="dollars">$</Option>
    </Select>
  );
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADSET_LABELS.BID_AMOUNT}
          </p>
          <InputNumber
            onChange={(value) => onChange(value, 'bidAmount')}
            className="w-[32vw]"
            size="large"
            addonBefore={selectBefore}
            disabled={disable}
            style={disable ? { backgroundColor: '#f0f0f0', color: '#666' } : {}}
          />
          {bidAmount === 'LOWEST_COST_WITHOUT_CAP' && (
            <p className="text-xs font-light  text-slate-800 opacity-50">
              You cannot set Bid amount when Bid Strategy is set to Lowest cost
              without cap
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
const KeywordsSettings = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            Search keywords
          </p>
          <Select
            mode="tags"
            className="w-[32vw]"
            placeholder="Search Keywords"
            onChange={(value) => {
              onChange(value, 'search');
            }}
          />
        </div>
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            Negative keywords
          </p>
          <Select
            mode="tags"
            className="w-[32vw]"
            placeholder="Negative Keywords"
            onChange={(value) => {
              onChange(value, 'negative');
            }}
          />
        </div>
      </div>
    </div>
  );
};

const Dot = (props) => {
  const type =
    props.type === 'dark'
      ? 'bg-ca_primary text-white'
      : 'bg-ca_secondary text-ca_primary';
  return (
    <div
      className={`rounded-full w-[40px] h-[40px] flex items-center justify-center ${type}`}
    >
      {props.value}
    </div>
  );
};

const AdsetsForm = ({ onFinish, platform, bidStrategy }) => {
  const [adSetData, setadSetData] = useState({
    adSetName: '',
    adSetGoal: {
      adSetBuyingType: '',
      adSetOptimizationGoal: '',
    },
    ageRange: {
      minAge: null,
      maxAge: null,
    },
    location: [],
    genders: [],
    bidAmount: null,
    keywords: {
      negative: [],
      search: [],
    },
  });

  const handleChange = (value, field, nestedField = null) => {
    setadSetData((prevState) => {
      if (nestedField) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [nestedField]: value,
          },
        };
      }
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      const adSetFormData = {
        name: adSetData.adSetName,
        billing_event: adSetData.adSetGoal.adSetBuyingType,
        targeting: {
          min_age: adSetData.ageRange.minAge,
          max_age: adSetData.ageRange.maxAge,
          location: adSetData.location,
          genders: adSetData.genders,
          neg_keywords: adSetData.keywords.negative,
          search_keywords: adSetData.keywords.search,
        },
        bid_amount: adSetData.bidAmount,
      };
      onFinish(adSetFormData);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };
  const platformValue = platform && platform.platform;

  const timeLineItems = [
    {
      dot: <Dot value="1.1" type="dark" />,
      children: (
        <AdsSetsName
          title="Adset Name"
          onChange={(value) => handleChange(value, 'adSetName')}
        />
      ),
    },
    {
      dot: <Dot value="1.2" type="light" />,
      children: (
        <AdsSetGoal
          title="Adset Goal"
          onChange={(value, field) => handleChange(value, 'adSetGoal', field)}
          platform={platform}
        />
      ),
    },
    {
      dot: <Dot value="1.3" type="light" />,
      children: (
        <AgeRange
          title="Age Range"
          onChange={(value, field) => handleChange(value, 'ageRange', field)}
        />
      ),
    },
    {
      dot: <Dot value="1.4" type="light" />,
      children: (
        <BidAmount
          title="Bid Amount"
          onChange={(value) => handleChange(value, 'bidAmount')}
          bidAmount={bidStrategy}
        />
      ),
    },

    {
      dot: <Dot value="1.5" type="light" />,
      children: (
        <Gender
          title="Gender"
          onChange={(value) => handleChange(value, 'genders')}
        />
      ),
    },
  ];
  if (platformValue === 'fbads') {
    timeLineItems.push({
      dot: <Dot value="1.6" type="light" />,
      children: (
        <Location
          title="Location"
          onChange={(value) => handleChange(value, 'location')}
        />
      ),
    });
  }
  if (platformValue === 'gads') {
    timeLineItems.push({
      dot: <Dot value="1.6" type="light" />,
      children: (
        <KeywordsSettings
          title="Keywords"
          onChange={(value, field) => handleChange(value, 'keywords', field)}
        />
      ),
    });
  }
  const handleBackClick = () => {};
  const handleAdsFormSubmit = () => {};
  return (
    <div className="overflow-auto h-[92vh]">
      {/* <div className="px-8">
        <div className="flex flex-row items-center justify-between">
          <div className="bg- h-[calc(7vh-2px)] flex items-center gap-4 font-semibold text-md">
            <img
              src={CampaignBack}
              alt=""
              className="w-[28px]"
              onClick={handleBackClick}
              style={{ cursor: 'pointer' }}
            />{' '}
            Add New Adset
          </div>
          <div className="flex flex-row items-center gap-4">
            <Button
              size="middle"
              type="default"
              onClick={() => {
                window.location.href = '/adsets';
              }}
            >
              Back
            </Button>
            <Button size="middle" type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div> */}
      <Divider className="m-0" />
      <div className="p-5">
        <Timeline className="m-5" items={timeLineItems} />
        <div className="form-buttons-container">
          <Button
            type="default"
            onClick={() => {
              window.location.href = '/adsets';
            }}
          >
            Back
          </Button>
          <Form.Item>
            <Button
              className="bg-ca_primary"
              type="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default AdsetsForm;

import './App.css';
import './globals.scss';

import React from 'react';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import Header from './components/Header/Header';
import ContentWrapper from './components/Wrapper/ContentWrapper';
import Accounts from './pages/Accounts/Accounts';
import Ads from './pages/Ads/Ads';
import CreateAds from './pages/Ads/CreateAds';
import Adsets from './pages/Adsets/Adsets';
import CreateAdsets from './pages/Adsets/createAdsets';
import CampaignDetails from './pages/Campaigns/Campaign-details/campaignDetails';
import Campaigns from './pages/Campaigns/Campaigns/campaigns';
import CreateCampaigns from './pages/Campaigns/Create-campaigns/create-campaigns';
import Landing from './pages/Landing/landing';
import Signup from './pages/Signup/signup';
import ProtectedRoute from './routes/ProtectedRoutes';

const App = () => {
  return (
    <div className="h-screen">
      <Header />
      <Router>
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/" exact element={<Signup />} />

          <Route
            path="/dashboard"
            element={
              <ContentWrapper>
                <ProtectedRoute element={Landing} />
              </ContentWrapper>
            }
          />
          <Route
            path="/campaign/create"
            element={
              <ContentWrapper>
                <ProtectedRoute element={CreateCampaigns} />
              </ContentWrapper>
            }
          />
          <Route
            path="/adsets/create"
            element={
              <ContentWrapper>
                <ProtectedRoute element={CreateAdsets} />
              </ContentWrapper>
            }
          />
          <Route
            path="/ads/create"
            element={
              <ContentWrapper>
                <ProtectedRoute element={CreateAds} />
              </ContentWrapper>
            }
          />
          <Route
            path="/campaigns"
            element={
              <ContentWrapper>
                <ProtectedRoute element={Campaigns} />
              </ContentWrapper>
            }
          />
          <Route
            path="/campaigns/:id/:platform"
            element={
              <ContentWrapper>
                <ProtectedRoute element={CampaignDetails} />
              </ContentWrapper>
            }
          />

          <Route
            path="/adsets"
            element={
              <ContentWrapper>
                <ProtectedRoute element={Adsets} />
              </ContentWrapper>
            }
          />
          <Route
            path="/ads"
            element={
              <ContentWrapper>
                <ProtectedRoute element={Ads} />
              </ContentWrapper>
            }
          />
          <Route
            path="/integration"
            element={
              <ContentWrapper>
                <ProtectedRoute element={Accounts} />
              </ContentWrapper>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

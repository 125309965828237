import toast from 'react-hot-toast';
import { CheckCircle, XCircle, Info, AlertTriangle } from 'lucide-react';
import React from 'react';

const showToast = (type, title, message) => {
  const icons = {
    success: <CheckCircle className="h-5 w-5 text-green-500" />,
    error: <XCircle className="h-5 w-5 text-red-500" />,
    info: <Info className="h-5 w-5 text-blue-500" />,
    warning: <AlertTriangle className="h-5 w-5 text-yellow-500" />,
  };

  toast.custom((t) => {
    return (
      <div
        className={`${
          t.visible ? 'animate-enter' : 'animate-leave'
        } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-2 px-3">
          <div className="flex items-center">
            <div className="flex-shrink-0 pt-0.5 flex items-center">
              {icons[type]}
            </div>
            <div className="ml-3 flex-1">
              <p className="text-xs font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-xs text-gray-500">{message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default showToast;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campaignName: '',
  budget: {
    currency: '',
    value: '',
  },
  // Add new fields here
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState: initialState,
  reducers: {
    setCampaignName(state, action) {
      state.campaignName = action.payload.val;
    },
    // methods to update those states.

    // reset
    reset(state, action) {
      state = { ...initialState };
      return state;
    },
  },
});

// Update the export to include other reducers.
export const { setCampaignName, reset } = campaignSlice.actions;
export default campaignSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-navigation_dw_menu_item__KUjfZ {

    display: flex;

    cursor: pointer;

    align-items: center;

    border-radius: 0px
}

.sidebar-navigation_dw_menu_item__KUjfZ:last-child {

    margin-top: auto;

    display: flex;

    flex-direction: column;

    justify-content: center;

    gap: 0px
}

.sidebar-navigation_dw_menu_item__KUjfZ:first-child {

    margin-top: 2px
}

.sidebar-navigation_ant-menu-item__\\+eroy {

    margin: 0px;

    margin-bottom: 2px;

    height: unset;

    min-height: 42px;

    width: 100%;

    border-radius: 0.5rem;

    background-color: transparent;

    padding-top: 7.5px;

    padding-bottom: 7.5px;

    padding-left: 10px;

    padding-right: 10px;

    font-family: Inter, sans-serif;

    font-size: 1rem;

    font-weight: 400;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(102 111 141 / var(--tw-text-opacity))
}

.sidebar-navigation_ant-menu-item__\\+eroy:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(236 247 255 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(111 92 217 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/SideNavbar/sidebar-navigation.module.css"],"names":[],"mappings":"AACE;;IAAA,aAAoD;;IAApD,eAAoD;;IAApD,mBAAoD;;IAApD;AAAoD;;AAIpD;;IAAA,gBAAiD;;IAAjD,aAAiD;;IAAjD,sBAAiD;;IAAjD,uBAAiD;;IAAjD;AAAiD;;AAIjD;;IAAA;AAAe;;AAIf;;IAAA,WAA0M;;IAA1M,kBAA0M;;IAA1M,aAA0M;;IAA1M,gBAA0M;;IAA1M,WAA0M;;IAA1M,qBAA0M;;IAA1M,6BAA0M;;IAA1M,kBAA0M;;IAA1M,qBAA0M;;IAA1M,kBAA0M;;IAA1M,mBAA0M;;IAA1M,8BAA0M;;IAA1M,eAA0M;;IAA1M,gBAA0M;;IAA1M,oBAA0M;;IAA1M,oBAA0M;;IAA1M;AAA0M;;AAA1M;;IAAA,kBAA0M;;IAA1M,yDAA0M;;IAA1M,oBAA0M;;IAA1M;AAA0M","sourcesContent":[".dw_menu_item {\n  @apply flex items-center cursor-pointer rounded-none;\n}\n\n.dw_menu_item:last-child {\n  @apply mt-auto flex flex-col gap-0 justify-center;\n}\n\n.dw_menu_item:first-child {\n  @apply mt-[2px];\n}\n\n.ant-menu-item {\n  @apply bg-transparent text-ca_menu_color text-base font-inter font-normal leading-5 rounded-lg w-full min-h-[42px] h-[unset] m-0 mb-[2px] py-[7.5px] px-[10px] hover:bg-ca_light_btn hover:text-ca_primary;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dw_menu_item": `sidebar-navigation_dw_menu_item__KUjfZ`,
	"ant-menu-item": `sidebar-navigation_ant-menu-item__+eroy`
};
export default ___CSS_LOADER_EXPORT___;

import { Notification } from 'src/utilities/notification.util';

import axios from '../config/axios.config';

const AdService = {
  addCreative: async (creativeData) => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      return await axios.post('carter/creatives-add', creativeData, {
        headers: {
          email: userDetails.email || '',
          'account-id': '1378888112738553',
          'access-type': 'USER',
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  fetchAllAds: async () => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      return await axios.get('carter/list-ads', {
        headers: {
          email: selectedUser?.email,
          'fb-account-id': selectedUser?.metaAccountId || null,
          'ga-account-id': selectedUser?.googleAccountId || null,
          'access-type': 'USER',
        },
      });
    } catch (error) {
      if (error?.response?.data?.stack_trace) {
        Notification('error', 'Something went wrong while fetching Ads');
      }
      console.log(error);
    }
  },
  fetchPages: async () => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      return await axios.get(`carter/pages?email=${selectedUser?.email}`);
    } catch (error) {
      if (error?.response?.data?.stack_trace) {
        Notification('error', 'Something went wrong while fetching Ads');
      }
      console.log(error);
    }
  },
};
export default AdService;

import Axios from 'axios';
import config from './app.config';

const axios = Axios.create({
  baseURL: config.baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let res = error.response;
    console.log('error', res);
    return Promise.reject(error);
  }
);

export default axios;

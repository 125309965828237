import './index.css';

import React from 'react';

import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import App from './App';
import store from './redux/store';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          // token:{colorText:'white'},
          components: {
            Button: {
              // defaultActiveBorderColor: GlobalCSS.ca_primary,
              // defaultActiveColor: GlobalCSS.ca_primary,
              // defaultHoverBorderColor: GlobalCSS.ca_primary,
              // defaultHoverColor: GlobalCSS.ca_primary,
              // defaultBorderColor: GlobalCSS.ca_primary,
              // // defaultBg:GlobalCSS.ca_primary
              // // defaultPressBorderColor: GlobalCSS.ca_primary,
              // // defaultPressColor: GlobalCSS.ca_primary,
              // // defaultSize: 'large',
              // // defaultWidth: '100%',
              // defaultColor:GlobalCSS.ca_primary,
              // primaryColor: GlobalCSS.ca_primary,
              // colorPrimaryText: GlobalCSS.ca_primary,
              // colorText:GlobalCSS.ca_primary,
              // colorTextDescription:GlobalCSS.ca_primary,
              // colorPrimary:GlobalCSS.ca_primary
            },
            Modal: {},
          },
        }}
      >
        <RecoilRoot>
          <div>
            <Toaster position="top-right" />
          </div>
          <App />
        </RecoilRoot>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

import { Notification } from 'src/utilities/notification.util';

import axios from '../config/axios.config';

const AdSetService = {
  adSetCreate: async (adSetData) => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      return await axios.post('carter/create-adset', adSetData, {
        headers: {
          email: userDetails.email || '',
          'account-id': '1378888112738553',
          'access-type': 'USER',
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  createAd: async (adData) => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      return await axios.post('carter/create-ad', adData, {
        headers: {
          email: userDetails.email || '',
          'account-id': '1378888112738553',
          'access-type': 'USER',
        },
      });
    } catch (error) {
      console.log(error);
    }
  },

  fetchAllAdsSets: async () => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      return await axios.get('carter/adset-list', {
        headers: {
          email: selectedUser?.email,
          'fb-account-id': selectedUser?.metaAccountId || null,
          'ga-account-id': selectedUser?.googleAccountId || null,
          'access-type': 'USER',
        },
      });
    } catch (error) {
      Notification('error', 'Issue', 'Error while fetching the adsets list.');
      console.log(error);
    }
  },
};
export default AdSetService;

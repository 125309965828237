import React, { useEffect, useState } from 'react';

import { Button, Divider, Segmented } from 'antd';
import { useNavigate } from 'react-router-dom';
import CampaignsService from 'src/services/campaigns.service';

import CampaignBack from '../../../assets/svg/campaign-back.svg';
import { NOTIFICATION } from '../../../common/constants';
import AdsForm from '../../../components/Forms/ads.form';
import AdsetsForm from '../../../components/Forms/adsets.form';
import CreateCampaignForm from '../../../components/Forms/campaign.form';
import { Notification } from '../../../constants/notifications';

function CreateCampaigns() {
  const navigate = useNavigate();
  const [activeForm, setActiveForm] = useState('Campaigns');
  const [campaignData, setCampaignData] = useState(null);
  const [adsetsData, setAdsetsData] = useState(null);
  const [adsData, setAdsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeFormComponent, setActiveFormComponent] = useState(null);

  useEffect(() => {
    setActiveFormComponent(renderActiveForm());
  }, [activeForm]);

  const isCampaignFormComplete = () => {
    return campaignData && Object.values(campaignData).every((field) => field);
  };

  const isAdsetFormComplete = () => {
    return adsetsData && Object.values(adsetsData).every((field) => field);
  };
  const isAdsFormComplete = () => {
    return adsData && Object.values(adsData).every((field) => field);
  };

  const renderActiveForm = () => {
    switch (activeForm) {
      case 'Campaigns':
        return (
          <CreateCampaignForm
            onFinish={(values) =>
              onFinish(
                values,
                setCampaignData(values),
                handleCampaignFormData,
                () => {}
              )
            }
          />
        );
      case 'Adsets':
        return (
          <AdsetsForm
            onFinish={(values) =>
              onFinish(
                values,
                setAdsetsData(values),
                handleAdsetFormData,
                () => {}
              )
            }
            platform={campaignData?.platform}
            bidStrategy={campaignData?.campaign_data?.bid_strategy}
          />
        );
      case 'Ads':
        return (
          <AdsForm
            onFinish={(values) =>
              onFinish(
                values,
                setAdsData,
                handleAdsFormData,
                createCampaignFlow
              )
            }
            platform={campaignData?.platform}
          />
        );
      default:
        return null;
    }
  };

  const renderActionButton = () => {
    if (activeForm === 'Ads') {
      return (
        <Button size="middle" type="primary" onClick={handleAdsFormSubmit}>
          Submit
        </Button>
      );
    } else {
      return (
        <Button disabled size="middle" type="primary" onClick={handleNextClick}>
          Next
        </Button>
      );
    }
  };

  const handleAdsFormSubmit = () => {
    if (isAdsFormComplete()) {
      createCampaignFlow(adsData);
    }
  };

  <AdsForm
    onFinish={(values) =>
      onFinish(values, setAdsData, handleAdsFormData, () => {})
    }
    platform={campaignData?.platform}
  />;

  const onFinish = (values, dataSetter, validator, extraAction) => {
    if (values !== null || values.length > 0) {
      const validate = validator(values);
      if (validate) {
        dataSetter(values);
        extraAction(values);
      }
    }
  };

  const createCampaignFlow = async (ad_data) => {
    const { start_time, end_time } = campaignData.campaign_data;
    adsetsData.start_time = start_time;
    adsetsData.end_time = end_time;
    const payloadBody = {
      campaign_data: campaignData.campaign_data,
      adset_data: adsetsData,
      creative_data: ad_data.creative_data,
      ad_data: ad_data.ad_data,
      platform: [campaignData.platform.platform],
    };
    setIsLoading(true);
    const response = await CampaignsService.createCampaign(payloadBody);
    if (response?.status === 201) {
      setTimeout(() => {
        Notification('success', 'Campaign created successfully');
        navigate('/campaigns');
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleCampaignFormData = (campaignInput) => {
    if (
      campaignInput.daily_budget !== undefined &&
      campaignInput.lifetime_budget !== undefined
    ) {
      Notification(
        NOTIFICATION.ENUMS.WARNING,
        NOTIFICATION.MESSAGE.SET_LIFETIME_DAILY_BUDGET
      );
    } else if (campaignInput.campaign_data.daily_budget < 100) {
      Notification(NOTIFICATION.ENUMS.WARNING, NOTIFICATION.MESSAGE.LOW_BUDGET);
    } else if (
      (campaignInput.campaign_data.objective === 'SEARCH' &&
        (campaignInput.campaign_data.target_google_search === false ||
          campaignInput.campaign_data.target_search_network === false)) ||
      (campaignInput.campaign_data.objective === 'SEARCH' &&
        (!campaignInput.campaign_data.target_google_search ||
          !campaignInput.campaign_data.target_search_network))
    ) {
      Notification(
        NOTIFICATION.ENUMS.WARNING,
        NOTIFICATION.MESSAGE.TARGETING_ERROR_SEARCH
      );
    } else {
      setActiveForm('Adsets');
    }
  };

  const handleAdsetFormData = (adsetInput) => {
    if (
      campaignData?.campaign_data?.daily_budget / 8 <=
      adsetInput.bid_amount
    ) {
      Notification(
        NOTIFICATION.ENUMS.WARNING,
        NOTIFICATION.MESSAGE.GREATER_THAN_BUDGET
      );
    } else {
      setActiveForm('Ads');
    }
  };
  const handleAdsFormData = (adsInput) => {
    try {
      const url = new URL(adsInput.creative_data.display_url);
      return true;
    } catch (error) {
      console.error(error);
      Notification(
        NOTIFICATION.ENUMS.WARNING,
        NOTIFICATION.MESSAGE.DISPLAY_URL_PROTOCOL
      );
    }
  };
  const handleBackClick = () => {
    navigate('/campaigns');
  };
  const handleNextClick = () => {
    if (activeForm === 'Campaigns' && isCampaignFormComplete()) {
      setActiveForm('Adsets');
    } else if (activeForm === 'Adsets' && isAdsetFormComplete()) {
      setActiveForm('Ads');
    }
  };

  return (
    <div className="">
      <div className="px-10 ">
        <div className="flex flex-row items-center justify-between">
          <div className="bg- h-[calc(7vh-2px)] flex items-center gap-4 font-semibold text-md">
            <img
              src={CampaignBack}
              alt=""
              className="w-[28px]"
              onClick={handleBackClick}
              style={{ cursor: 'pointer' }}
            />{' '}
            Add New Campaign
          </div>
          <div className="flex flex-row items-center gap-4">
            <Button size="middle" type="default">
              Back
            </Button>
            {renderActionButton()}
          </div>
        </div>
      </div>
      <Divider className="m-0" />
      <div className="px-10 overflow-scroll h-[80vh] bg-slate-100">
        <div className="flex flex-row bg-white p-5 mt-5 rounded-lg mb-10">
          <Tab
            completed
            title="Campaign Details"
            step="1"
            onClick={() => {
              setActiveForm('Campaigns');
            }}
          />
          <Tab
            title="Adsets"
            step="2"
            onClick={() => {
              if (isCampaignFormComplete()) {
                setActiveForm('Adsets');
              }
            }}
          />
          <Tab
            title="Ads"
            step="3"
            onClick={() => {
              if (isCampaignFormComplete() && isAdsetFormComplete()) {
                setActiveForm('Ads');
              }
            }}
          />
        </div>
        <div className="bg-white p-5 rounded-lg">
          <Segmented
            options={[
              {
                label: '',
                value: 'Campaigns',
                className: 'hidden',
              },
              {
                label: '',
                value: 'Adsets',
                className: 'hidden',
              },
              {
                label: '',
                value: 'Ads',
                className: 'hidden',
              },
            ]}
            onChange={(value) => {
              setActiveForm(value);
            }}
            value={activeForm}
          />
          {activeFormComponent}
        </div>
      </div>
    </div>
  );
}

const Tab = (props) => {
  const completed = props.completed;
  return (
    <div
      className="w-1/3 flex flex-row gap-2 items-center cursor-pointer"
      onClick={props.onClick}
    >
      <div
        className={`${
          completed ? 'bg-ca_primary' : 'bg-ca_secondary'
        } w-8 h-8 items-center  justify-center flex rounded-full ${
          completed ? 'text-white' : 'text-ca_primary'
        } text-xs`}
      >
        {props.step}
      </div>
      <div className="flex flex-col gap-[6px] h-fit">
        <div className="text-xs">{props.title}</div>
        <div
          className={`w-[20vw] h-1 ${
            completed ? 'bg-ca_primary' : 'bg-ca_secondary'
          } rounded-lg`}
        ></div>
      </div>
    </div>
  );
};
export default CreateCampaigns;

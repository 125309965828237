import React from 'react';
import { ConfigProvider } from 'antd';
import SideNavbar from '../SideNavbar/SideNavbar';

export default function ContentWrapper({ children }) {
  return (
    <ConfigProvider>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '1', display: 'flex' }}>
          <SideNavbar />
          <div style={{ flex: '1', overflowY: 'auto' }}>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

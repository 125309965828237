import React, { useEffect, useState } from 'react';

import { ConfigProvider, Tabs, Tag } from 'antd';
import { ArrowUp } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LineChart from 'src/charts/ad-revenue';
import AccountModal from 'src/components/Modal/AccountModal';
import {
  setFacebookAuth,
  setFacebookStateCodeScope,
  setGoogleAuth,
  setGoogleStateCodeScope,
  setUserEmailName,
} from 'src/redux/slices/auth.slice';

import { useCustomEffect } from '../../hooks/customUseEffect';
import UserService from '../../services/user-actions.service';

const Landing = () => {
  const [loadingAccountModal, setLoadingAccountModal] = useState(true);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModalToSelectAccount, setShowModalToSelectAccount] =
    useState(false);
  useEffect(() => {
    const userData = localStorage.getItem('selectedUser');
    if (userData === null) {
      setShowModalToSelectAccount(true);
    }
  }, []);

  const handleOk = () => {
    setShowModalToSelectAccount(false);
  };

  const handleCancel = () => {
    setShowModalToSelectAccount(false);
  };

  const handleQueryParams = async () => {
    try {
      const scope = params.get('scope');
      const isGoogleAdwordsLogin =
        scope && scope.includes('www.googleapis.com/auth/adwords');

      let response;
      if (params.get('scope') === null && params.get('code') === null) {
        navigate('/dashboard');
      } else {
        if (isGoogleAdwordsLogin) {
          response = await UserService.google_details({
            code: params.get('code'),
            state: params.get('state'),
            scope: params.get('scope'),
          });
          const payload = {
            state: params.get('state'),
            scope: params.get('scope'),
            code: params.get('code'),
          };
          dispatch(setGoogleStateCodeScope(payload));
        } else {
          response = await UserService.facebook_details({
            code: params.get('code'),
            state: params.get('state'),
          });
          const payload = {
            state: params.get('state'),
            code: params.get('code'),
          };
          dispatch(setFacebookStateCodeScope(payload));
        }
        const userDetails = response.data.data;
        if (response?.data?.status_code === 201) {
          localStorage.setItem('userDetails', JSON.stringify(userDetails));
          const payload = {
            name: userDetails.name,
            email: userDetails.email,
            user_flag: userDetails.user_flag,
          };
          dispatch(setUserEmailName(payload));
          const facebookAuthPayload = {
            connected_accounts: userDetails.facebook_account.connected_accounts,
            page_type: userDetails.facebook_account.page_type,
            account_type: userDetails.facebook_account.account_type,
            page_access_token: userDetails.facebook_account.page_access_token,
          };
          const googleAuthPayload = {
            connected_accounts: userDetails.google_account.connected_accounts,
            account_type: userDetails.google_account.account_type,
          };
          dispatch(setFacebookAuth(facebookAuthPayload));
          dispatch(setGoogleAuth(googleAuthPayload));
          window.dispatchEvent(new Event('storage'));
        }
        if (userDetails.user_flag === true) {
          navigate('/integration');
        } else {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.error('Error fetching details:', error);
    }
    setLoadingAccountModal(false);
  };

  useCustomEffect(() => {
    handleQueryParams();
  }, []);

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className="m-4">
      <AccountModal
        isVisible={showModalToSelectAccount}
        onOk={handleOk}
        onCancel={handleCancel}
        loading={loadingAccountModal}
      />
      <Tabs
        onChange={onChange}
        type="card"
        tabBarGutter={10}
        className="active:text-ca_primary"
        items={[
          {
            label: (
              <p className="focus:text-ca_primary text-lg mx-5">Campaigns</p>
            ),
            key: 'campaigns',
            children: <CampaignsTabItem />,
          },
          {
            label: <p className="focus:text-ca_primary text-lg mx-5">Adsets</p>,
            key: 'adsets',
            children: <CampaignsTabItem />,
          },
          {
            label: <p className="focus:text-ca_primary text-lg mx-5">Ads</p>,
            key: 'ads',
            children: <CampaignsTabItem />,
          },
        ]}
      />
    </div>
  );
};

const CustomTag = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: '#227409',
        },
      }}
    >
      <Tag
        color="#CEF8C4"
        className="flex flex-row align-middle justify-center items-center py-[1px] px-[2px] font-semibold gap-1 text-[10px] mr-0 h-fit"
        icon={<ArrowUp size={14} strokeWidth={3} color="#227409" />}
      >
        <span className="text-[#227409]">{props.change}</span>
      </Tag>
    </ConfigProvider>
  );
};

const CardPerformance = (props) => {
  return (
    <div className="my-5 mx-1 border w-[19%] rounded-lg min-h-[12vh] max-h-[12vh] h-[12vh] flex flex-col justify-between">
      <div className="flex flex-row m-3 mb-0 justify-between">
        <p className="text-[12px] text-ca_text_secondary">{props.title}</p>
        {props.change && <CustomTag change={props.change} />}
      </div>
      <p className="px-3 pb-4 text-[1rem]">{props.data}</p>
    </div>
  );
};

const CampaignsTabItem = () => {
  const [chartData, setChartData] = useState({
    labels: [
      'March 09, 2024',
      'March 10, 2024',
      'March 11, 2024',
      'March 12, 2024',
      'March 13, 2024',
      'March 16, 2024',
      'March 17, 2024',
    ],
    values1: [65, 59, 80, 81, 56, 55, 40], // Initial data for Dataset 1
    values2: [30, 40, 20, 10, 60, 80, 95], // Initial data for Dataset 2
  });

  // Function to update chart data

  const [facebookMetrics, setFacebookMetrics] = useState([]);
  const [googleMetrics, setGoogleMetrics] = useState([]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await MetricsService.getBothMetrics();
  //       setFacebookMetrics(response[0]);
  //       setGoogleMetrics(response[1]);
  //     } catch (err) {
  //       Notification(NOTIFICATION.ERROR, JSON.stringify(err));
  //     }
  //   }
  //   fetchData();
  // }, []);

  // const [timeoutFacebookMetric, setTimeoutFacebookMetric] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimeoutFacebookMetric(false);
  //   }, 5000);
  // }, []);

  return (
    <div className="m-4 rounded-t-lg border max-h-[76vh] overflow-scroll">
      <div className="bg-[#d9d9d938] px-5 py-3 rounded-t-lg text-[16px]">
        Campaigns Performance (Last 30 Days)
      </div>

      <div className="m-4 rounded-t-lg border">
        <div className="bg-[#d9d9d938] px-5 py-2 rounded-t-lg text-[16px]">
          Facebook
        </div>
        <div className="flex flex-row">
          <div className="flex flex-row px-5 flex-wrap">
            {Object.keys(facebookMetrics).map(
              (metric, index) =>
                metric !== 'platform' && (
                  <CardPerformance
                    key={index}
                    title={metric.toUpperCase() || ''}
                    data={
                      (typeof facebookMetrics[metric] === 'number' &&
                        !Number.isInteger(facebookMetrics[metric]) &&
                        facebookMetrics[metric].toFixed(2)) ||
                      facebookMetrics[metric]
                    }
                    // change={metric}
                  />
                )
            )}

            {/* {facebookMetrics.length === 0 && timeoutFacebookMetric ? (
              <div className="flex align-middle justify-center w-full h-full items-center">
                <Spin />
              </div>
            ) : null} */}
            {/* <div className="text-red-600 font-light text-xs py-3 flex justify-center align-middle items-center">
                <Ban className="mr-2" size={16} />
                Response timeout. Please select account from Select Account tab.
              </div> */}
          </div>
        </div>
      </div>
      <div className="m-4 rounded-t-lg border">
        <div className="bg-[#d9d9d938] px-5 py-2 rounded-t-lg text-[16px]">
          Google
        </div>
        <div className="flex flex-row">
          <div className="flex flex-row px-5 flex-wrap">
            {Object.keys(googleMetrics).map(
              (metric, index) =>
                metric !== 'platform' && (
                  <CardPerformance
                    key={index}
                    title={metric.toUpperCase() || ''}
                    data={
                      (typeof googleMetrics[metric] === 'number' &&
                        !Number.isInteger(googleMetrics[metric]) &&
                        googleMetrics[metric].toFixed(2)) ||
                      googleMetrics[metric]
                    }
                    // change={metric}
                  />
                )
            )}

            {/* {googleMetrics.length === 0 && timeoutFacebookMetric ? (
              <div className="flex align-middle justify-center w-full h-full items-center">
                <Spin />
              </div>
            ) : null} */}
            {/* <div className="text-red-600 font-light text-xs py-3 flex justify-center align-middle items-center">
                <Ban className="mr-2" size={16} />
                Response timeout. Please select account from Select Account tab.
              </div> */}
          </div>
        </div>
      </div>
      <div className="m-4 rounded-t-lg border min-h-[50vh]">
        <div className="bg-[#d9d9d938] px-5 py-3 rounded-t-lg text-[16px]">
          Ad Revenue
        </div>
        <div className="flex flex-row m-5">
          <LineChart data={chartData} />
        </div>
      </div>
    </div>
  );
};

export default Landing;

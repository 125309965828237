import axios from '../config/axios.config';
// import { Notification } from 'src/utilities/notification.util';

const CampaignsService = {
  fetchAllCampaigns: async () => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      const response = await axios.get('carter/campaigns', {
        headers: {
          email: selectedUser?.email || '',
          'fb-account-id': selectedUser?.metaAccountId || null,
          'ga-account-id': selectedUser?.googleAccountId || null,
          'access-type': 'USER',
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      // Notification('error', 'Something went wrong while fetching campaign')
    }
  },

  createCampaign: async (campaignData) => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      return await axios.post('carter/campaign-flow', campaignData, {
        headers: {
          email: selectedUser?.email || '',
          'fb-account-id': selectedUser?.metaAccountId || null,
          'ga-account-id': selectedUser?.googleAccountId || null,
          'access-type': 'USER',
        },
      });
    } catch (error) {
      // Notification('error', `Something went wrong while creating campaign ${error.response?.data?.data?.message}`)
    }
  },

  fetchCampaignDetails: async (campaign_id, platform) => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      return await axios.get(
        `carter/campaign-data?campaign_id=${campaign_id}&platform=${platform}`,
        {
          headers: {
            email: userDetails?.email || '',
            'fb-account-id': selectedUser?.metaAccountId || null,
            'ga-account-id': selectedUser?.googleAccountId || null,
            'access-type': 'USER',
          },
        }
      );
    } catch (error) {
      console.log(error);
      // Notification('error', 'Something went wrong while fetching campaign details')
    }
  },

  adSetDetails: async (campaign_id, platform) => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      return await axios.get(
        `carter/adset-details?campaign_id=${campaign_id}&platform=${platform}`,
        {
          headers: {
            email: userDetails?.email || '',
            'fb-account-id': selectedUser?.metaAccountId || null,
            'access-type': 'USER',
          },
        }
      );
    } catch (error) {
      console.log(error);
      // Notification('error', 'Something went wrong while fetching adsets')
    }
  },

  adsDetails: async (campaign_id, platform) => {
    try {
      const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      return await axios.get(
        `carter/ad-details?campaign_id=${campaign_id}&platform=${platform}`,
        {
          headers: {
            email: userDetails?.email || '',
            'fb-account-id': selectedUser?.metaAccountId || null,
            'access-type': 'USER',
          },
        }
      );
    } catch (error) {
      console.log(error);
      // Notification('error', 'Something went wrong while fetching adset details')
    }
  },
};

export default CampaignsService;

import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    value: [],
  },
  reducers: {
    update: (state, action) => {
      state.value.push(action.payload);
    },
    reset: (state, action) => {
      state.value = [];
    },
  },
});

export const { update, reset } = navigationSlice.actions;
export default navigationSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  max-height: 1000px;
  width: 1000px; /* Increase width */
  height: 500px;
  margin: 100px auto 20px auto; /* Increase margin on top and bottom, set auto for left and right */
  display: block;
  padding: 30px;
  background-color: #ffffff; /* Change background color to white */
  border-radius: 20px; /* Increase border radius for smoother edges */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Add a soft shadow */
}

.form-item-label {
  font-weight: bold;
  color: #333;
}

.form-buttons-container {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 30px; */
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .form-container {
    padding: 20px;
  }

  .form-buttons-container {
    flex-direction: column;
    align-items: flex-end;
    /* margin-top: 40px; */
  }
}

/* Additional styling if needed */
.form-buttons-container > * {
  margin-left: 10px; /* Add some spacing between the buttons */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Campaign.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa,EAAE,mBAAmB;EAClC,aAAa;EACb,4BAA4B,EAAE,mEAAmE;EACjG,cAAc;EACd,aAAa;EACb,yBAAyB,EAAE,qCAAqC;EAChE,mBAAmB,EAAE,8CAA8C;EACnE,0CAA0C,EAAE,sBAAsB;AACpE;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA,sBAAsB;AACtB;EACE;IACE,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,qBAAqB;IACrB,sBAAsB;EACxB;AACF;;AAEA,iCAAiC;AACjC;EACE,iBAAiB,EAAE,yCAAyC;AAC9D","sourcesContent":[".form-container {\n  max-height: 1000px;\n  width: 1000px; /* Increase width */\n  height: 500px;\n  margin: 100px auto 20px auto; /* Increase margin on top and bottom, set auto for left and right */\n  display: block;\n  padding: 30px;\n  background-color: #ffffff; /* Change background color to white */\n  border-radius: 20px; /* Increase border radius for smoother edges */\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Add a soft shadow */\n}\n\n.form-item-label {\n  font-weight: bold;\n  color: #333;\n}\n\n.form-buttons-container {\n  display: flex;\n  justify-content: flex-end;\n  /* margin-top: 30px; */\n}\n\n/* Responsive styles */\n@media screen and (max-width: 768px) {\n  .form-container {\n    padding: 20px;\n  }\n\n  .form-buttons-container {\n    flex-direction: column;\n    align-items: flex-end;\n    /* margin-top: 40px; */\n  }\n}\n\n/* Additional styling if needed */\n.form-buttons-container > * {\n  margin-left: 10px; /* Add some spacing between the buttons */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

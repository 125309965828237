import React, { useState } from 'react';

import AdsetsForm from '../../components/Forms/adsets.form';
import AdSetService from '../../services/adsets.service';
import { Notification } from 'src/constants/notifications';

function CreateAdsets() {
  const [adsetsData, setAdsetsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleAdsetFormData = (adsetInput) => {
    if (adsetInput.bid_amount !== null && adsetInput.bid_amount !== undefined) {
      Notification(
        'warning',
        'You can only set bid amount for Campaigns with Lowest cost bid cap strategy'
      );
    } else {
      createAddset(adsetInput);
    }
  };
  const validateData = (data) => {
    return {
      name: data.name,
      billing_event: data.billing_event,
      optimization_goal: data.optimization_goal,
      campaign_id: '120208372599510721',
      targeting: {
        age_min: data.min_age,
        age_max: data.max_age,
        geo_locations: {
          countries: ['IN'],
        },
      },
      start_time: '2024-05-02T20:06:23.763Z',
      end_time: '2024-05-30T20:06:23.763Z',
      status: 'PAUSED',
    };
  };
  const createAddset = async () => {
    let campaignId;
    if (adsetsData !== null && adsetsData !== undefined) {
      setIsLoading(true);
      adsetsData.campaign_id = campaignId;
      const validatedAdsetData = validateData(adsetsData);
      const response = await AdSetService.adSetCreate(validatedAdsetData);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <AdsetsForm
          onFinish={(values) => {
            if (adsetsData !== null) {
              handleAdsetFormData(values);
            }
          }}
          setAdsetsData={setAdsetsData}
          onCreate={() => {
            createAddset();
          }}
        />
      </div>
    </>
  );
}

export default CreateAdsets;

import './campaings.module.scss';

import React, { useEffect, useState } from 'react';

import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import DynamicListTable from '../../../components/Tables/Tables';
import { allCampaignsTableColumns } from '../../../constants/columns';
import CampaignsService from '../../../services/campaigns.service';

const Campaigns = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fullDataWithoutFilter, setFullDataWithoutFilter] = useState([]);

  const createCampaignHandler = () => {
    navigate('/campaign/create');
  };
  const fetchAllCampaigns = async () => {
    setIsLoading(true);
    const { data } = await CampaignsService.fetchAllCampaigns();
    setTableData(data || []);
    setFullDataWithoutFilter(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllCampaigns();
  }, []);

  useEffect(() => {
    const handleAccountSelection = () => {
      fetchAllCampaigns();
    };
    window.addEventListener('accountSelected', handleAccountSelection);
    return () => {
      window.removeEventListener('accountSelected', handleAccountSelection);
    };
  }, []);

  const handleRowClick = (row) => {
    navigate(`/campaigns/${row.id}/${row.platform}`);
  };

  const onSearchCampaignsChangeHandler = (event) => {
    // If the value in the search box is empty, then
    // display the main data.
    if (event.target.value === '') setTableData(fullDataWithoutFilter);
    else
      setTableData(
        fullDataWithoutFilter.filter((adset) =>
          adset.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
  };

  return (
    <div className="flex flex-col">
      <div className="flex m-[30px] justify-between items-center align-middle">
        <Input
          size="large"
          placeholder={`Search Campaigns`}
          className="placeholder:text-sm w-[32vw]"
          onPressEnter={onSearchCampaignsChangeHandler}
          suffix={<p className="text-xs font-light">Please enter to search</p>}
        />
        <Button
          onClick={createCampaignHandler}
          size="middle"
          className="bg-ca_primary text-white hover:bg-ca_primary_90"
        >
          Create Campaign
        </Button>
      </div>
      <div className="mx-[30px]">
        <DynamicListTable
          loading={isLoading}
          type="campaigns"
          columns={allCampaignsTableColumns}
          dataSource={tableData}
          rowKey={tableData.id}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default Campaigns;

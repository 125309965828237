import React, { useEffect } from 'react';

import { Button, notification } from 'antd';
import { Chrome, Facebook } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import carterLogo from '../../assets/svg/carter-one.svg';
import UserService from '../../services/user-actions.service';

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Clear off the localstorage on login page/signup page
    localStorage.clear();
    sessionStorage.clear();
  });

  const handleFacebookLogin = async () => {
    try {
      const response = await UserService.facebook_login();
      if (response.data.status_code === 200) {
        const authUrl = response.data.data.auth_url[0];

        window.location.href = authUrl;
      } else {
        notification.error({
          message: 'Error',
          description: response?.data?.detail || response?.data?.message,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: error?.response?.data?.detail,
      });
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await UserService.google_login();
      if (response.status === 200) {
        const authUrl = response.data.data;
        window.location.href = authUrl;
      } else {
        notification.error({
          message: 'Error',
          description: response?.data?.detail,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: error?.response?.data?.detail,
      });
    }
  };

  const handleGuestLogin = async () => {
    try {
      localStorage.setItem('authenticated', 'true');
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: error?.response?.data?.detail,
      });
    }
  };

  return (
    <div className="w-full flex flex-row h-full mt-[-8vh] bg-white relative">
      <div className="w-1/2 bg-slate-50 opacity-80 flex items-center justify-center">
        <img src="svg/login-signup.svg" alt="" width={600} />
      </div>
      <div className="w-1/2 justify-between flex flex-col">
        <div key={'content'}>
          {/* <h1 className="text-4xl font-medium p-20">carter</h1> */}
          <img className="p-20" src={carterLogo} alt="LOGO" width={350} />

          <h1 className="text-4xl font-medium p-20 py-5">Let's get started</h1>
          <h1 className="text-sm px-20 font-light">
            Please sign in to access the latest insights of your retail media
            work <br />
            performance.
          </h1>
          <div className="flex flex-col gap-4 items-start px-20 pt-10 justify-center">
            <Button
              icon={<Chrome className="p-1" />}
              size={'middle'}
              className="w-52 flex bg-[#6F5CD9] text-white"
              onClick={handleGoogleLogin}
            >
              Sign in with Google
            </Button>
            <Button
              icon={<Facebook className="p-1" />}
              size={'middle'}
              className="w-52 flex bg-[#6F5CD9] text-white"
              onClick={handleFacebookLogin}
            >
              Sign in with Facebook
            </Button>
            {/* <Button
              icon={<UserRoundX className="p-1" />}
              size={"middle"}
              className="w-52 flex bg-ca_secondary border-ca_primary_light text-ca_primary"
              onClick={handleGuestLogin}
            >
              Continue as Guest
            </Button> */}
          </div>
        </div>
        <div key={'footer'} className="text-xs p-20 pb-5 font-light">
          {'© 2024 Carter Campaigns. All Rights Reserved.'}
        </div>
      </div>
    </div>
  );
};

export default Signup;

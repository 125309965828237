import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import AdsForm from '../../components/Forms/ads.form';
import AdService from '../../services/ads.service';
import AdSetService from '../../services/adsets.service';

function CreateAds() {
  const [adsData, setAdsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const validateData = (data) => {
    return {
      name: data.creative_name,
      page_id: data.page_id,
      cta_type: data.cta_type,
      cta_link: data.cta_link,
      message: data.message,
      description: data.description,
      link: data.link,
      adset_id: data.adset_id,
      creative_id: data.creative_id,
      name: data.name,
      status: 'PAUSED',
    };
  };

  const createAddset = async () => {
    let creativeId, adSetId;
    if (adsData !== null && adsData !== undefined) {
      setIsLoading(true);
      const validatedCreativeData = validateData('creatives', adsData);
      const response = await AdService.addCreative(validatedCreativeData);
      if (response.status === 201) {
        creativeId = response.data.data.id;
        adsData.creative_id = creativeId;
        adsData.adset_id = adSetId;
        const validatedAdData = validateData(adsData);
        const adResponse = await AdSetService.createAd(validatedAdData);
      }
      setIsLoading(false);
    }

    navigate('/ads');
  };

  return (
    <>
      <div>
        <AdsForm
          onFinish={(values) => {}}
          setAdsData={setAdsData}
          onCreate={() => {
            if (adsData !== null) {
              createAddset();
            }
          }}
        />
      </div>
    </>
  );
}

export default CreateAds;

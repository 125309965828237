import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Input, Row, Skeleton, Table, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CAMPAIGN_DETAILS } from 'src/common/constants';

import {
  AreaChartOutlined,
  CheckCircleOutlined,
  EditOutlined,
  PauseCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import CampaignBack from '../../../assets/svg/campaign-back.svg';
import {
  adsCampiagnDetails,
  adsetCampaignDetails,
} from '../../../constants/columns';
import CampaignsService from '../../../services/campaigns.service';

const CampaignDetails = () => {
  const { id, platform } = useParams();
  const [campaignData, setCampaignData] = useState([]);
  const [adsetData, setAdsetData] = useState([]);
  const [adsData, setAdsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // const { data, error, isLoading } = useQuery('users', fetchUsers);

  useEffect(() => {
    if (id) {
      fetchCampaignDetails(id, platform);
      adSetDetails(id, platform);
      adsDetails(id, platform);
    }
  }, [id, platform]);

  const adsDetails = async () => {
    setLoading(true);
    const response = await CampaignsService.adsDetails(id, platform);
    const fetchedAdsData = response?.data?.data;
    // console.log("Ads : ", fetchedAdsData[0]);
    setAdsData(fetchedAdsData);
    // setCampaignData(fetchedTableData[0]);
    setLoading(false);
  };

  const adSetDetails = async () => {
    setLoading(true);
    const response = await CampaignsService.adSetDetails(id, platform);
    const fetchedAdsetData = response?.data?.data;
    // console.log("Adsetdata : ", fetchedAdsetData[0]);
    setAdsetData(fetchedAdsetData);
    // setCampaignData(fetchedTableData[0]);
    setLoading(false);
  };

  const fetchCampaignDetails = async () => {
    setIsLoading(true);
    const response = await CampaignsService.fetchCampaignDetails(id, platform);
    const CampaignData = response?.data?.data;
    setCampaignData(CampaignData[0] || []);
    setIsLoading(false);
  };

  const SummaryTags = (props) => {
    return (
      <p className="font-poppins font-medium text-sm mt-2.5">
        {props.title}:{' '}
        <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
          {props.value}
        </span>
      </p>
    );
  };

  const CampaignSummary = (props) => {
    return (
      <div className="border border-solid border-0.5 w-1/2 h-72 p-3 rounded-lg gap-7 border-ca_background_80">
        <p className="">{props.header}</p>
      </div>
    );
  };

  const CampaignPerformance = (props) => {
    return (
      <div className="border-solid border-0.5 w-1/3 h-32 rounded-lg p-3 border-ca_background_80 flex flex-col justify-between">
        <p className="text-ca_bodytext">{props.title}</p>
        <p className="text-xl text-ca_typography mt-4">{props.value}</p>
      </div>
    );
  };
  const dateFormatter = (dateg) => {
    const date = new Date(dateg);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };
  const handleBackClick = () => {
    navigate('/campaigns');
  };
  return (
    <>
      <div className="">
        <div className="px-10">
          <div className="flex flex-row items-center justify-between">
            <div className="bg- h-[calc(7vh-2px)] flex items-center gap-4 font-semibold text-md">
              <img
                src={CampaignBack}
                alt=""
                className="w-[28px]"
                onClick={handleBackClick}
                style={{ cursor: 'pointer' }}
              />{' '}
              {campaignData?.name}
            </div>
            <div className="flex flex-row items-center gap-4">
              <Button
                className="flex flex-row items-center justify-between rounded"
                type="default"
              >
                <EditOutlined /> <span>{CAMPAIGN_DETAILS.EDIT_CAMPAIGN}</span>
              </Button>
              <Button
                className="flex flex-row items-center justify-between bg-ca_primary"
                type="primary"
              >
                {CAMPAIGN_DETAILS.SUBMIT}
              </Button>
            </div>
          </div>
        </div>
        {/* <Divider className="m-0" /> */}

        <div className="px-10 overflow-scroll h-[80vh] bg-slate-100">
          <div className="mt-5 p-5 px-0">
            {isLoading ? (
              <Row gutter={16}>
                <Col span={12} className="h-full">
                  <Skeleton active paragraph={{ rows: 12 }} />
                </Col>
                <Col span={12} className="h-[30vh]">
                  <Skeleton active paragraph={{ rows: 12 }} />
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col span={12}>
                  <Card className="h-full">
                    <div className="flex justify-between">
                      <h1 className="font-poppins font-semibold text-lg">
                        {CAMPAIGN_DETAILS.SUMMARY}
                      </h1>
                      <h3>
                        {/* {CAMPAIGN_DETAILS.STATUS}:{'   '} */}
                        <span>
                          {campaignData?.status === null ? (
                            <span className="bg-ca_accent font-roboto font-normal text-xs rounded-full p-1 px-3">
                              <PauseCircleOutlined className="mr-1" />{' '}
                              {CAMPAIGN_DETAILS.PAUSED}
                            </span>
                          ) : (
                            <span className="bg-ca_light_green2 font-roboto font-normal text-xs rounded-full px-3 py-1">
                              <CheckCircleOutlined className="mr-1" />{' '}
                              {CAMPAIGN_DETAILS.ACTIVE}
                            </span>
                          )}
                        </span>{' '}
                      </h3>
                    </div>
                    <div className="flex gap-3 justify-between mt-8">
                      <div className="border border-solid border-0.5  w-1/2 h-72 p-3 rounded-lg gap-7 border-ca_background_80">
                        <p className="font-poppins font-medium text-base mb-5">
                          Campaign Details
                        </p>
                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Name:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            {campaignData?.name}
                          </span>
                        </p>

                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Advertiser:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            ---
                          </span>
                        </p>

                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Platform:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            {campaignData?.platform}
                          </span>
                        </p>

                        <p className="font-poppins font-medium text-sm mt-2.5">
                          ID:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            {campaignData?.id}
                          </span>
                        </p>

                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Created On:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            ---
                          </span>
                        </p>

                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Created by:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            ---
                          </span>
                        </p>
                      </div>
                      <div className="border border-solid border-0.5  w-1/2 h-72 p-3 rounded-lg gap-7 border-ca_background_80">
                        <p className="font-poppins font-medium text-base mb-5">
                          Campaign Settings
                        </p>
                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Goal:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            ---
                          </span>
                        </p>
                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Type:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            {campaignData?.buying_type}
                          </span>
                        </p>
                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Link Wallet:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            ---
                          </span>
                        </p>
                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Budget:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            {campaignData?.daily_budget}
                          </span>
                        </p>
                        <p className="font-poppins font-medium text-sm mt-2.5">
                          Start Date:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            {dateFormatter(campaignData?.start_time)}
                          </span>
                        </p>
                        <p className="font-poppins font-medium text-sm mt-2.5">
                          End Date:{' '}
                          <span className="font-roboto font-medium text-sm ml-1 text-ca_bodytext">
                            {dateFormatter(campaignData?.stop_time)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card className="h-full">
                    <div className="flex justify-between">
                      <h1 className="font-poppins font-semibold text-lg">
                        {CAMPAIGN_DETAILS.PERFORMANCE}
                      </h1>
                      <h3>
                        <u>{CAMPAIGN_DETAILS.GRAPH}:</u> <AreaChartOutlined />
                      </h3>
                    </div>
                    <div className="flex gap-3 mt-8">
                      <CampaignPerformance
                        title="Impressions"
                        value={
                          campaignData?.campaign_insights?.[0]?.impressions ||
                          '---'
                        }
                      />
                      <CampaignPerformance
                        title="Overall Ad Spent"
                        value={
                          campaignData?.campaign_insights?.[0]?.spend || '---'
                        }
                      />
                      <CampaignPerformance
                        title="Reach"
                        value={
                          campaignData?.campaign_insights?.[0]?.reach || '---'
                        }
                      />
                    </div>
                    <div className="flex gap-3 mt-8">
                      <CampaignPerformance
                        title="Clicks"
                        value={
                          campaignData?.campaign_insights?.[0]?.clicks || '---'
                        }
                      />
                      <CampaignPerformance
                        title="CTR"
                        value={
                          campaignData?.campaign_insights?.[0]?.ctr || '---'
                        }
                      />
                      <CampaignPerformance
                        title="CPC"
                        value={
                          campaignData?.campaign_insights?.[0]?.cpc || '---'
                        }
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
          </div>

          <Tabs
            className="active:text-ca_primary p-5 px-0 mt-0"
            defaultActiveKey="1"
            type="card"
            items={[
              {
                label: (
                  <p className="focus:text-ca_primary text-sm mx-5">
                    {CAMPAIGN_DETAILS.ADSETS_TAB}
                  </p>
                ),
                key: 'campaigns',
                children: (
                  <div className="bg-white p-5">
                    <Input
                      placeholder="Search Ad groups"
                      prefix={
                        <SearchOutlined
                          style={{
                            color: 'rgba(0,0,0,.25)',
                          }}
                        />
                      }
                      className="w-80 h-11 my-2"
                    />
                    <Table
                      columns={adsetCampaignDetails}
                      dataSource={adsetData}
                      className="my-3"
                      loading={loading}
                    />
                  </div>
                ),
              },
              {
                label: (
                  <p className="focus:text-ca_primary text-sm mx-5">
                    {CAMPAIGN_DETAILS.ADS_TAB}
                  </p>
                ),
                key: 'adsets',
                children: (
                  <div className="bg-white p-5">
                    <Input
                      prefix={
                        <SearchOutlined
                          style={{
                            color: 'rgba(0,0,0,.25)',
                          }}
                        />
                      }
                      placeholder="Search Creatives"
                      className="w-80 h-11 my-2"
                    />
                    <Table
                      columns={adsCampiagnDetails}
                      dataSource={adsData}
                      className="my-3"
                      loading={loading}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default CampaignDetails;

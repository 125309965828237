// ADS FORM
export const ADS_LABELS = {
  ADS_NAME: 'Adset Name * ',
  CREATIVE_NAME: 'Creative Name *',
  PAGE_ID: 'Page ID ',
  TEXT: 'Headline *',
  DESCRIPTION: 'Description *',
  LINK_URL: 'Link Url',
  CALL_ACTION_TYPE: 'Call to Action type ',
  CALL_ACTION_LINK: 'Call to Action Link ',
};
// ADSET FORM
export const ADSET_LABELS = {
  PAYING_EVENT: 'Paying Event *',
  OPTIMIZATION_GOAL: 'Optimization Goal *',
  ADSET_NAME: 'Adset Name *',
  BID_AMOUNT: 'Bid Amount *',
  MIN_AGE: 'Min Age *',
  MAX_AGE: 'Max Age *',
};
// CAMPIAGN FORM
export const CAMPAIGN_LABELS = {
  CAMPAIGN_TYPE: 'Campaign Type *',
  CAMPAIGN_OBJ: 'Campaign Objective *',
  CAMPAIGN_NAME: 'Campaign Name *',
  START_DATE: 'Start Date *',
  END_DATE: 'End Date *',
  BIDDING_STRATEGY: 'Bidding Strategy *',
  DAILY_BUDGET: 'Daily Budget *',
  BUDGET_DELIVERY_TYPE: 'Budget Delivery Type (Google Ads) *',
};
export const NOTIFICATION = {
  ENUMS: {
    WARNING: 'warning',
  },
  MESSAGE: {
    SET_LIFETIME_DAILY_BUDGET:
      'Spend Cap can not be less than Daily or Lifetime Budget',
    LOW_BUDGET:
      'Specified budget is too low to run a campaign, please set budget greater than 100',
    LOW_SPEND_CAP:
      'Spend Cap is too low to run a campaign, please set greater than 100',
    INVALID_BUDGET: 'Spend Cap can not be less than Daily or Lifetime Budget',
    LOWEST_COST_WITH_BID_CAP:
      'You can only set bid amount for Campaigns with Lowest cost bid cap strategy',
    TARGETING_ERROR_SEARCH:
      'Targeting for google and search must be enabled for Search Campaigns ',
    DISPLAY_URL_PROTOCOL: 'Display URL must have either http or https',
    CAMPAIGN_OLDER_START_DATE:
      'Campaign start date is set to date older than current date',
    GREATER_THAN_BUDGET: 'Bidding to high for a specified budget',
  },
};
export const ACCOUNT_CONSTANTS = {
  CONNECTED_TEXT: 'Active',
  NOT_CONNECTED_TEXT: 'Inactive',
  DISCONNECTED_TEXT: 'Disconnect',
  USER_NAME_LABEL: 'Name',
  USER_EMAIL_LABEL: 'Email',
  EDIT_BUTTON_TEXT: 'Edit',
  RESET_CONNECTION_LABEL: 'Reset Connection',
};

export const CAMPAIGN_DETAILS = {
  EDIT_CAMPAIGN: 'Edit campaign',
  SUBMIT: 'Sumbit for Appproval',
  SUMMARY: 'Campaign Summary',
  PERFORMANCE: 'Campaign Performance',
  STATUS: 'Status',
  PAUSED: 'Paused',
  ACTIVE: 'Active',
  GRAPH: 'View graph',
  ADSETS_TAB: 'Adsets',
  ADS_TAB: 'Creative',
};

export const disabledDate = (current) => {
  return current && current < new Date().setHours(0, 0, 0, 0);
};

import { combineReducers } from '@reduxjs/toolkit';

import authSlice from './slices/auth.slice';
import navigationSlice from './slices/navigation.slice';
import campaignSlice from './slices/campaigns.slice';

const rootReducer = combineReducers({
  navigation: navigationSlice,
  auth: authSlice,
  campaign: campaignSlice,
});

export default rootReducer;

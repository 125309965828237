import React, { useEffect, useState } from 'react';

import { Button, Divider, Form, Input, Select, Timeline } from 'antd';
import { ChevronDown } from 'lucide-react';
import AdService from 'src/services/ads.service';

import { ADS_LABELS } from '../../common/constants';

const { TextArea } = Input;

const Dot = (props) => {
  const type =
    props.type === 'dark'
      ? 'bg-ca_primary text-white'
      : 'bg-ca_secondary text-ca_primary';
  return (
    <div
      className={`rounded-full w-[40px] h-[40px] flex items-center justify-center ${type}`}
    >
      {props.value}
    </div>
  );
};
const FBPage = ({ title, onChange, platform }) => {
  const platformValue = platform && platform.platform;
  const isFbAds = platformValue === 'gads';
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPages = async () => {
      setLoading(true);
      const response = await AdService.fetchPages();
      setPages(response?.data?.data || []);
      setLoading(false);
    };

    fetchPages();
  }, []);

  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADS_LABELS.PAGE_ID}
          </p>
          <Select
            onChange={(value) => onChange(value, 'pageId')}
            size="large"
            className="w-[32vw]"
            disabled={isFbAds}
            loading={loading}
            placeholder="Select a Facebook Page"
            style={isFbAds ? { backgroundColor: '#f0f0f0', color: '#666' } : {}}
          >
            {pages.map((page) => (
              <Select.Option key={page} value={page}>
                {page.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};
const AdsName = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}

      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADS_LABELS.ADS_NAME}
          </p>
          <Input
            onChange={(e) => onChange(e.target.value, 'adName')}
            size="large"
            className="w-[32vw]"
            required
          />
        </div>
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADS_LABELS.CREATIVE_NAME}
          </p>
          <Input
            onChange={(e) => onChange(e.target.value, 'creativeName')}
            size="large"
            className="w-[32vw]"
            required
          />
        </div>
      </div>
    </div>
  );
};

const AdsHeadline = ({ title, onChange, platform }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADS_LABELS.TEXT}
          </p>
          <TextArea
            showCount
            maxLength={20}
            className="w-[32vw]"
            size={'large'}
            allowClear
            onChange={(e) => onChange(e.target.value, 'creativeText1')}
          />
        </div>
        {platform === 'gads' && (
          <>
            <div>
              <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
                {ADS_LABELS.TEXT}
              </p>
              <TextArea
                showCount
                maxLength={20}
                className="w-[32vw]"
                size={'large'}
                allowClear
                onChange={(e) => onChange(e.target.value, 'creativeText2')}
              />
            </div>
            <div>
              <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
                {ADS_LABELS.TEXT}
              </p>
              <TextArea
                showCount
                maxLength={20}
                className="w-[32vw]"
                size={'large'}
                allowClear
                onChange={(e) => onChange(e.target.value, 'creativeText3')}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const AdsDescription = ({ title, onChange, platform }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADS_LABELS.DESCRIPTION}
          </p>
          <Input
            className="w-[32vw]"
            size={'large'}
            onChange={(e) => onChange(e.target.value, 'creativeDescription')}
          />
        </div>
        {platform === 'gads' && (
          <div>
            <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
              {ADS_LABELS.DESCRIPTION}
            </p>
            <Input
              className="w-[32vw]"
              size={'large'}
              onChange={(e) => onChange(e.target.value, 'creativeDescription2')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const AdsUrl = ({ title, onChange, platform }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            Display Url
          </p>
          <Input
            className="w-[32vw]"
            size={'large'}
            onChange={(e) => onChange(e.target.value, 'displayUrl')}
          />
        </div>
        {platform === 'gads' && (
          <>
            <div>
              <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
                Display path
              </p>
              <Input
                className="w-[32vw]"
                size={'large'}
                onChange={(e) => onChange(e.target.value, 'displayPath1')}
              />
            </div>
            <div>
              <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
                Display path
              </p>
              <Input
                className="w-[32vw]"
                size={'large'}
                onChange={(e) => onChange(e.target.value, 'displayPath2')}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const AdsActions = ({ title, onChange }) => {
  return (
    <div className="ml-6 mb-12">
      {/* Title */}
      <div className="text-lg mb-4">{title}</div>
      {/* Fields */}
      <div className="flex flex-col gap-6">
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADS_LABELS.CALL_ACTION_TYPE}
          </p>
          <Select
            className="w-[32vw]"
            size={'large'}
            onChange={(value) => onChange(value, 'ctaType')}
            options={[
              { label: 'Open Link', value: 'OPEN_LINK', key: 'Open Link' },
            ]}
            suffixIcon={
              <ChevronDown
                size={16}
                strokeWidth={1}
                className="text-ca_primary bg-ca_secondary rounded-full"
              />
            }
          />
        </div>
        <div>
          <p className="text-xs font-light mb-1 text-slate-600 opacity-50">
            {ADS_LABELS.CALL_ACTION_LINK}
          </p>
          <Input
            className="w-[32vw]"
            size={'large'}
            onChange={(e) => onChange(e.target.value, 'ctaLink')}
          />
        </div>
      </div>
    </div>
  );
};

const AdsForm = ({ onFinish, platform }) => {
  const [loading, setLoading] = useState(false);
  const [adData, setadData] = useState({
    adsName: {
      adName: '',
      creativeName: '',
    },
    pageId: '',
    actions: {
      ctaType: '',
      ctaLink: '',
    },
    adsHeadline: {
      creativeText1: '',
      creativeText2: '',
      creativeText3: '',
    },
    adsDescriptions: {
      creativeDescription: '',
      creativeDescription2: '',
    },
    adsUrl: {
      displayUrl: '',
      displayPath1: '',
      displayPath2: '',
    },
  });

  const handleChange = (value, field, nestedField = null) => {
    setadData((prevState) => {
      if (nestedField) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [nestedField]: value,
          },
        };
      }
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const adsData = {
        creative_data: {
          name: adData.adsName.creativeName,
          headlines: [
            adData.adsHeadline.creativeText1,
            adData.adsHeadline.creativeText2,
            adData.adsHeadline.creativeText3,
          ],
          descriptions: [
            adData.adsDescriptions.creativeDescription,
            adData.adsDescriptions.creativeDescription2,
          ],

          page_id: adData.pageId,
          cta_type: adData.actions.ctaType,
          cta_link: adData.actions.ctaLink,
          display_url: adData.adsUrl.displayUrl,
          path_1: adData.adsUrl.displayPath1,
          path_2: adData.adsUrl.displayPath2,
        },
        ad_data: {
          name: adData.adsName.adName,
        },
      };
      setTimeout(() => {
        setLoading(false);
        console.log('Async operation complete');
        onFinish(adsData);
      }, 2000);
    } catch (error) {
      console.error('Validation failed:', error);
      setLoading(false);
    }
  };
  const platformValue = platform && platform.platform;
  const timeLineItems = [
    {
      dot: <Dot value="1.1" type="light" />,
      children: (
        <AdsHeadline
          title="Ads Headline"
          onChange={(value, field) => handleChange(value, 'adsHeadline', field)}
          platform={platformValue}
        />
      ),
    },
    {
      dot: <Dot value="1.2" type="light" />,
      children: (
        <AdsDescription
          title="Ads Description"
          onChange={(value, field) =>
            handleChange(value, 'adsDescriptions', field)
          }
          platform={platformValue}
        />
      ),
    },
    {
      dot: <Dot value="1.3" type="light" />,
      children: (
        <AdsUrl
          title="Ads Url"
          onChange={(value, field) => handleChange(value, 'adsUrl', field)}
          platform={platformValue}
        />
      ),
    },
  ];
  if (platformValue === 'fbads') {
    timeLineItems.unshift(
      {
        dot: <Dot value="3.1" type="dark" />,
        children: (
          <AdsName
            title="Ads Name"
            onChange={(value, field) => handleChange(value, 'adsName', field)}
          />
        ),
      },
      {
        dot: <Dot value="3.2" type="light" />,
        children: (
          <FBPage
            title="FB Page ID"
            onChange={(value) => handleChange(value, 'pageId')}
            platform={platform}
          />
        ),
      },
      {
        dot: <Dot value="3.3" type="light" />,
        children: (
          <AdsActions
            title="Actions"
            onChange={(value, field) => handleChange(value, 'actions', field)}
          />
        ),
      }
    );
  }

  const [form] = Form.useForm();
  const handleBackClick = () => {
    window.location.href = '/ads';
  };
  return (
    <div>
      {/* <div className="px-8">
        <div className="flex flex-row items-center justify-between">
          <div className="bg- h-[calc(7vh-2px)] flex items-center gap-4 font-semibold text-md">
            <img
              src={CampaignBack}
              alt=""
              className="w-[28px]"
              onClick={handleBackClick}
              style={{ cursor: 'pointer' }}
            />{' '}
            Add New Ad
          </div>
          <div className="flex flex-row items-center gap-4">
            <Button
              size="middle"
              type="default"
              onClick={() => {
                window.location.href = '/adsets';
              }}
            >
              Back
            </Button>
            <Button size="middle" type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div> */}
      <Divider className="m-0" />
      <Timeline className="m-5 p-5" items={timeLineItems} />
      <div className="form-buttons-container pr-8">
        <Button type="default">Back</Button>
        <Form.Item>
          <Button
            className="bg-ca_primary"
            onClick={handleSubmit}
            loading={loading}
            type="primary"
          >
            {loading ? 'Loading...' : 'Submit'}
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export default AdsForm;
